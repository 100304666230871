import React from 'react';
import {
  Box, Container, Stack, Typography,
} from '@mui/material';
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
} from '@mui/lab';
import useResponsive from '../../hooks/useResponsive';
import { responsiveFontSizes } from '../../utils/getFontValue';

export const historyEntries = [
  {
    timelineColor: '#75B09C',
    year: '2021-Present',
    title: 'Co-Pilot Program',
    description:
      `VoiceIt started a new Co-Pilot Program and converted all products from Biometrics as a 
            Service (BaaS) to private cloud mode (PCM) or on-site to stay aligned with data privacy 
            laws worldwide and help lower cyber-security insurance for our customers.
            `,
  },
  {
    timelineColor: '#75B09C',
    year: '2019',
    title: 'Voiceprint Portal',
    description:
      `VoiceIt launched its Voiceprint Portal 2.0 APIs—adding multiple engines and 
            anti-noise algorithms to our backend systems to provide enhanced facial and voice biometrics. 
            In addition, we led the industry by providing the first multi-modal, 
            multi-engine biometric portal on the internet.
            `,
  },
  {
    timelineColor: '#75B09C',
    year: '2017',
    title: 'Facial Biometrics',
    description:
      `VoiceIt released a technology preview of our Voiceprint Portal 2.0 which 
            included facial biometrics for video verification and identification.
            `,
  },
  {
    timelineColor: '#75B09C',
    year: '2014',
    title: 'Twilio',
    description:
      `VoiceIt showcased its Voiceprint Portal technology at the first Twilio Developer Signal Conference and
             became the first biometric company to sponsor Twilio’s Developer Signal Conferences. 
             To this day, Twilio still features our company as their biometric solutions provider.
            `,
  },
  {
    timelineColor: '#75B09C',
    year: '2007',
    title: 'Biometric As A Service',
    description:
      `VoiceIt launched the first patented Voiceprint Portal on the internet. 
            We were first in the industry to offer Biometric as a Service (BaaS) RESTful APIs for voice biometrics worldwide. 
            During this time, VoiceIt transitioned from end consumer products to business-to-business customers.
            `,
  },
  {
    timelineColor: '#75B09C',
    year: '2005',
    title: 'VoiceIt Technologies',
    description:
      `VoiceIt Technologies was formed from Grover Industries and released VoiceLib SDK 1.0 for PalmOS, 
            the first voice recognition software development kit in the industry. VoiceIt worked directly with Palm to provide VoiceDialIt, 
            the first voice dialer for the PalmOS. We also produced VoiceSecureIt, the first voice biometric 
            unlock application for PalmOS and Windows Mobile smartphones; as well as VoiceNavIt, 
            the first voice navigation application for the PalmOS.`,
  },
  {
    timelineColor: '#75B09C',
    year: '1991',
    title: 'Grover Industries',
    description:
      `Starting in 1991, Grover Industries provided voice command and access control applications 
            such as VoiceNet VRS, VoiceSecureIt, and Web-TalkIt. VoiceSecureIt was the first in 
            the industry to enhance the MS Windows GINA and provide a secure voice login solution. 
            Web-TalkIt was honored as the best shareware by Windows Magazine and was selected by
            Microsoft for the Power Windows 98 Handbook.
            `,
  },
];

export default function About() {
  const isDesktop = useResponsive('up', 'md');
  const isLarge = useResponsive('up', 'lg');

  return (
    <Box sx={{
      height: 'auto',
      minHeight: { md: '100vh' },
      '@media (min-width: 600px)': {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
      marginTop: '64px',
      backgroundColor: '#EEF1EF',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
    >
      <Container sx={{
        py: { xs: 3, md: 3, lg: 9 },
      }}
      >
        <Stack
          spacing={3}
          sx={{
            maxWidth: { xs: 480, lg: 580 },
            textAlign: 'center',
            mx: 'auto',
            mb: { xs: 4, md: 8 },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: '#505050',
              ...responsiveFontSizes({
                xs: 28, sm: 30, md: 36, lg: 48,
              }),
            }}
          >
            Our Story

          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            For over 30 years, our company has been
            first in the market to provide unique biometric solutions to
            the evolving
            {
              isLarge && ' '
            }
            {!isLarge
              && <br />}
            menace of cyber-threats.
          </Typography>
        </Stack>
        <Timeline position={isDesktop ? 'alternate' : 'right'}>
          {historyEntries.map((value, index) => {
            const {
              title, description, year, timelineColor,
            } = value;
            return (
              <TimelineItem
                key={title}
                sx={{
                  '&:before': {
                    display: { xs: 'none', md: 'block' },
                  },
                }}
              >
                <TimelineSeparator>
                  <TimelineDot sx={{ backgroundColor: timelineColor }} />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ pt: 0, pb: { xs: 3, md: 5 } }}>
                  <Typography variant="subtitle3" sx={{ color: timelineColor }}>
                    {year}
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      mt: 0.5,
                      mb: 1,
                      ...responsiveFontSizes({
                        xs: 18, sm: 20, md: 25, lg: 32,
                      }),
                      color: '#505050',
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: 'text.secondary',
                      maxWidth: { md: 360 },
                      ...(index % 2 && {
                        ml: 'auto',
                      }),
                    }}
                  >
                    {description}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </Container>
    </Box>
  );
}
