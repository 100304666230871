/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Container,
  Divider,
  Stack,
  Avatar,
  Grid,
} from '@mui/material';
import format from 'date-fns/format';
import { useNavigate } from 'react-router-dom';
import Image from '../Image';
import TuckerAvatar from '../../assets/images/Tucker.jpg';
import NoelAvatar from '../../assets/images/Noel.png';
import RooAvatar from '../../assets/images/Roo.jpg';
import ArmaanAvatar from '../../assets/images/Armaan.jpg';
import MyFaceAndVoiceIdentifyMeCoverImage from '../../assets/images/CoverImages/MyFaceAndVoiceIdentifyMeCoverImage.jpg';
import MyFaceAndVoiceIdentifyMeSmallCoverImage from '../../assets/images/CoverImages/MyFaceAndVoiceIdentifyMeCoverImageSmall.jpg';
import FaaShutdownSmallCoverImage from '../../assets/images/CoverImages/FaaShutDownCoverImage.jpg';
import QuantumEncryptionDecryptedCoverImage from '../../assets/images/CoverImages/QuantumEncryptionDecryptedCoverImage.jpg';
import QuantumEncryptionDecryptedCoverImageSmall from '../../assets/images/CoverImages/QuantumEncryptionDecryptedCoverImageSmall.jpg';
import DeepFakesVsSecurityCoverImage from '../../assets/images/CoverImages/DeepFakesVsSecurityCoverImage.jpg';
import WhatIsCyberResilienceCoverImage from '../../assets/images/CoverImages/WhatIsCyberResilienceCoverImageSmall.jpg';
import ResponseCodesDemystifiedSmallCoverImage from '../../assets/images/CoverImages/ResponseCodesDemystifiedCoverImageSmall.jpg';
import VoicePrintPhrasesCoverImageSmall from '../../assets/images/CoverImages/VoicePrintPhrasesCoverImageSmall.jpg';
import TwoFaCoverImageSmall from '../../assets/images/CoverImages/TwoFaCoverImageSmall.jpg';
import BlogSidebar from './BlogSidebar';
import FeaturedBlog from './FeaturedBlog';
import getWindowSize from '../../utils/getScreenSize';
import { responsiveFontSizes } from '../../utils/getFontValue';

function fDate(date, option) {
  return format(new Date(date), option || 'dd MMM yyyy');
}

const Posts = [
  {
    title: 'The Voice Impersonation Showdown',
    duration: '7 mins',
    coverImgSm: DeepFakesVsSecurityCoverImage,
    coverImg: DeepFakesVsSecurityCoverImage,
    description: 'Deepfakes Meet Their Match in VoiceIt API 2.0 Biometric Voice Verification',
    author: {
      name: 'Roo Kosherbay',
      picture: RooAvatar,
    },
    createdAt: new Date('Feb 24, 2023'),
    internalLink: '/blog/deepFakesVsSecurity',
  },
  {
    title: 'My Face and Voice Identify Me',
    duration: '6 mins',
    coverImgSm: MyFaceAndVoiceIdentifyMeSmallCoverImage,
    coverImg: MyFaceAndVoiceIdentifyMeCoverImage,
    description: 'Your guide to VoiceIt API 2.0.',
    author: {
      name: 'Tucker Saxon',
      picture: TuckerAvatar,
    },
    createdAt: new Date('July 31, 2018'),
    internalLink: '/blog/myFaceAndVoiceIdentifyMe',
  },
  {
    title: 'Response Codes Demystified',
    duration: '3 mins',
    coverImgSm: ResponseCodesDemystifiedSmallCoverImage,
    useSmallImageInBlogCard: true,
    description: 'The good, the bad, and the ugly.',
    author: {
      name: 'Armaan Bindra',
      picture: ArmaanAvatar,
    },
    createdAt: new Date('August 21, 2017'),
    internalLink: '/blog/responseCodesDemystified',
  },
  {
    // eslint-disable-next-line react/jsx-one-expression-per-line
    title: <>2FA — Two Factor <br /> Antiquated</>,
    duration: '3 mins',
    coverImg: TwoFaCoverImageSmall,
    description: 'Why you should stop using SMS 2FA today.',
    author: {
      name: 'Tucker Saxon',
      picture: TuckerAvatar,
    },
    createdAt: new Date('November 22, 2017'),
    internalLink: '/blog/twoFa',
  },
  {
    // eslint-disable-next-line
    title: <>Voiceprint <br /> Phrases</>,
    duration: '2 mins',
    coverImg: VoicePrintPhrasesCoverImageSmall,
    description: 'The peanut butter to API 2.0’s biometric engine jelly.',
    author: {
      name: 'Tucker Saxon',
      picture: TuckerAvatar,
    },
    createdAt: new Date('August 25, 2017'),
    // targetLink: 'https://blog.voiceit.io/voiceprint-phrases-fdf5c6b8e3c7',
    internalLink: '/blog/voicePrintPhrases',
  },
];

export function BlogPostItem({ post }) {
  const {
    title, duration, coverImg, coverImgSm,
    description, author, targetLink,
    useSmallImageInBlogCard, internalLink,
  } = post;
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const navigate = useNavigate();

  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: 2,
        overflow: 'hidden',
        ':hover': {
          boxShadow: 20, // theme.shadows[20]
        },
      }}
      onClick={() => {
        if (internalLink) {
          navigate(internalLink);
        } else {
          window.open(targetLink, '_blank');
        }
      }}
    >
      <Image src={useSmallImageInBlogCard ? coverImgSm : coverImg} alt={title} ratio="1/1" />
      <Stack direction="row" spacing={3} sx={{ p: 3, height: '140px' }}>

        <Stack spacing={1} justifyContent="space-between" flexGrow={1}>
          <Typography
            variant="h6"
            sx={{
              ...responsiveFontSizes({
                xs: 26, sm: 26, md: 26, lg: 30,
              }),
              color: '#000000',
            }}
          >
            {title}
          </Typography>

          <Typography
            variant="body2"
            persistent
          >
            {description}
          </Typography>
        </Stack>

      </Stack>
      <Stack
        direction="row"
        spacing={3}
        sx={{ px: 3, pb: 3, pt: 0 }}
      >
        <Stack spacing={1.5} direction="row" alignItems="center" sx={{ pt: 1.5 }}>
          <Avatar src={author.picture} sx={{ width: 40, height: 40 }} />
          <Stack>
            <Typography variant="body2" sx={{ color: '#505050' }}>{author.name}</Typography>
            <Typography variant="caption" sx={{ color: '#505050' }}>
              {duration}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

    </Paper>
  );
}

export default function BlogLanding() {
  return (
    <>
      <Box sx={{
        height: 'auto',
        // minHeight: { md: '100vh' },
        backgroundColor: '#e3e6e4',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      >
        <Container sx={{
          py: { xs: 3, md: 3, lg: 10 },
          px: 0,
        }}
        >
          {/* <Typography variant="h2">Featured Blog:</Typography> */}
          <FeaturedBlog post={Posts[0]} />
        </Container>
      </Box>
      <Container
        sx={{
          mt: { xs: 4, md: 10 },
          pb: 4,
        }}
      >
        <Grid container columnSpacing={4} rowSpacing={4}>
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                display: 'grid',
                rowGap: { xs: 4, md: 5 },
                columnGap: 4,
                pr: { xs: 0, lg: 1.5 },
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                },
              }}
            >
              {/* <BlogPostItem post={Posts[0]} /> */}
              <BlogPostItem post={Posts[1]} />
              <BlogPostItem post={Posts[2]} />
              <BlogPostItem post={Posts[3]} />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <BlogSidebar />
          </Grid>
        </Grid>

      </Container>
    </>
  );
}
