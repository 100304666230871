/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  List as MuiList,
  ListItem as MuiListItem,
} from '@mui/material';
import ResponseCodesDemystifiedCoverImage from '../../../assets/images/CoverImages/ResponseCodesDemystifiedCoverImage.jpg';

import { responsiveFontSizes } from '../../../utils/getFontValue';
import BlogCodeSnippet from '../BlogCodeSnippet';

const exampleCode = `
//First use 
//npm install VoiceIt
//to install our Node Library. Then initialize it like this with your own developerId
//which you can find at voiceit.io/settings
myVoiceIt = require('VoiceIt');
myVoiceIt.initialize('YOUR_DEVELOPER_ID_HERE');

function startEnrollmentProcess(counter, enrollmentsDone){
  myVoiceIt.createEnrollment({
    userId: 'developerUserId',
    password: 'd0CHipUXOk',
    pathToEnrollmentWav: '/home/users/username/enroll' + counter + '.wav',
    contentLanguage: 'en-US',
    callback: function(response){
    var parsedJSON = JSON.parse(response2);
    if(parsedJSON.ResponseCode == "SUC"){
      counter++;
      if( counter >= 3 ) {
        enrollmentsDone();
      } else {
        startEnrollmentProcess(counter, enrollmentsDone);
      }
    }
  });
}
                             
function runEnrollmentsAndAuthentication(){
  startEnrollmentProcess(1, ()=>{
    // Enrollment Done Do Authentication
    myVoiceIt.authentication({
      userId: 'developerUserId',
      password: 'd0CHipUXOk',
      pathToAuthenticationWav: '/home/users/username/authentication.wav',
      contentLanguage: 'en-US',
      callback:(response2)=>{
        var parsedJSON2 = JSON.parse(response2);
        if(parsedJSON2.ResponseCode == "SUC"){
          //SUCCESSFUL AUTHENTICATION
          console.log("SUCCESSFULLY AUTHENTICATED THE USER");
        } else {
          //UNSUCCESSFUL AUTHENTICATION
          console.log("USER FAILED AUTHENTICATION PLEASE TRY AGAIN!");
        }
      }
    });
  });
}

myVoiceIt.getUser({
  userId: 'developerUserId',
  password: 'd0CHipUXOk',
  callback: (response)=>{
  var parsedJSON = JSON.parse(response);
  // User Exists so continue with enrollment process
  if(parsedJSON.ResponseCode == "SUC"){
      runEnrollmentsAndAuthentication();
  } else if (parsedJSON.ResponseCode == "UNF"){
    myVoiceIt.createUser({
    userId: 'developerUserId',
    password: 'd0CHipUXOk',
    callback: (response2)=>{
      var parsedJSON2 = JSON.parse(response2);
      if(parsedJSON2.ResponseCode == "SUC"){
        runEnrollmentsAndAuthentication();
      }
    });
  }
});
`;

export default function ResponseCodesDemystified() {
  return (
    <>
      <Box sx={{
        backgroundImage: `url(${ResponseCodesDemystifiedCoverImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100% 100%',
        marginTop: '64px',
      }}
      >
        <Container sx={{
          width: '100vw',
          height: '66vw',
          // height: {
          //   xs: '300px', sm: '500px', md: '700px', lg: '100vh',
          // },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#EEF1EF',
        }}
        />
      </Box>
      <Box
        sx={{
          '@media (min-width: 600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 6 },
          pb: { xs: 6, md: 6, lg: 9 },
        }}
        >
          <Grid
            container
            spacing={3}
            justifyContent={{ md: 'center' }}
          >
            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                  color: '#000000',
                  ...responsiveFontSizes({
                    xs: 34, sm: 34, md: 34, lg: 40,
                  }),
                }}
              >
                Response Codes Demystified
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                The good, the bad, and the ugly.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  color: '#000000',
                  ...responsiveFontSizes({
                    xs: 28, sm: 28, md: 28, lg: 32,
                  }),
                }}
              >
                Response codes are confusing
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                As you delve deeper into your project—making new decisions about workflow
                based on the VoiceIt API 2.0—ultimately, you’ll bump up against response codes.
                But you have no idea what any of them mean!

                Hey, we know that response codes take a little getting used to.
                Let’s take a few minutes to talk through the most important ones,
                and how you can use them to build a great voice authentication
                experience for your users.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  color: '#000000',
                  ...responsiveFontSizes({
                    xs: 28, sm: 28, md: 28, lg: 32,
                  }),
                }}
              >
                The Good
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                SUCC (Success)
              </Typography>

              <Typography>
                SUCC doesn’t suck. In fact, it’s the gold standard.
                If an API call returns a SUCC response,
                you’re good to go and can proceed to the next step!
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  color: '#000000',
                  ...responsiveFontSizes({
                    xs: 28, sm: 28, md: 28, lg: 32,
                  }),
                }}
              >
                The Bad
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                UNFD (User Not Found)
              </Typography>

              <Typography>
                UNFD means that the userID you provided
                could not be associated with an existing user.
                Double-check that you actually created a user with that ID.
              </Typography>
            </Grid>

            {/* <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                ENF (Enrollment Failed)
              </Typography>

              <Typography>
                An ENF response is triggered by two different situations.
                We do this to ensure the best on-boarding process for each of your end-users.
              </Typography>

              <MuiList>
                <MuiListItem>
                  1. When an previously-used enrollment file is reused.
                  We require each user to have a minimum of 3 unique enrollments.
                  (This helps us gather enough data to use in the authentication process.)
                </MuiListItem>
                <MuiListItem>
                  2. When a new enrollment is significantly
                  different than previous ones by the same user.
                  In this case, our system suspects that multiple
                  users are trying to enroll under the same user profile.
                </MuiListItem>
              </MuiList>
            </Grid> */}
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                STTF (Speech-To-Text Failed)
              </Typography>

              <Typography>
                STTF means that our speech-to-text engines
                were not able to decipher what the user said.
                You can get this code during an enrollment or authentication API call.
                When it happens, we suggest that you prompt the user
                to try again—repeating the phrase by clearly intoning every word.
              </Typography>
            </Grid>

            {/* <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                VPND : Voiceprint Phrase Not Detected
              </Typography>

              <Typography>
                With a VPND code, our engines couldn’t extract enough voice data from the recording.
                Make sure the user speaks their phrase for
                at least 1.2 seconds within a 5-second recording span.
                You can ensure this by choosing longer phrases.
                (Learn more about creating a custom Voiceprint Phrase here.)
              </Typography>
            </Grid> */}

            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  color: '#000000',
                  ...responsiveFontSizes({
                    xs: 28, sm: 28, md: 28, lg: 32,
                  }),
                }}
              >
                The Ugly
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                SRNR (Sound Recording Does Not Meet Requirements)
              </Typography>

              <Typography>
                If you get a SRNR code, it means that something about that
                particular audio recording was not “up to spec”.
                Most likely, it had one of the following issues:
              </Typography>

              <MuiList>
                <MuiListItem>
                  1. Recording length was less than 1.3 seconds (1300ms)
                </MuiListItem>
                <MuiListItem>
                  2. Recording length was more than 5 seconds (5000ms)
                </MuiListItem>
                <MuiListItem>
                  3. Recording volume was too low
                </MuiListItem>
              </MuiList>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Code Example
              </Typography>
              <Typography>
                Here is an example of how to utilize the
                response codes in your application logic using our Node Library:
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <BlogCodeSnippet code={exampleCode} />
            </Grid>

            <Grid item xs={12} md={8}>

              <Typography>
                Not finding a response code here? Have any other questions? Let us know!

              </Typography>
            </Grid>

          </Grid>

        </Container>
      </Box>

    </>
  );
}
