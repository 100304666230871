/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  List as MuiList,
  ListItem as MuiListItem,
  Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import VoiceVerificationParameters from '../../../assets/images/VoiceVerificationParameters.png';
import FaceVerificationParameters from '../../../assets/images/FaceVerificationParameters.png';
import VideoVerificationParameters from '../../../assets/images/VideoVerificationParameters.png';
import MyFaceAndVoiceIdentifyMeCoverImage from '../../../assets/images/CoverImages/MyFaceAndVoiceIdentifyMeCoverImage.jpg';
import PhraseManagementGif from '../../../assets/images/RequestNewPhrase.gif';
import DocsGif from '../../../assets/images/Docs.gif';

import { responsiveFontSizes } from '../../../utils/getFontValue';
import BlogCodeSnippet from '../BlogCodeSnippet';

const List = styled(MuiList)(() => ({
  // display: 'list-item',
  listStyleType: 'disc',
}));

const ListItem = styled(MuiListItem)(() => ({
  display: 'list-item',
  listStyleType: 'disc',
  marginLeft: '24px',
  pl: 1,
}));

const videoVerificationCode = `
const voiceit2 = require('voiceit2-nodejs');
let myVoiceIt = new voiceit2("<apiKey>", "<apiToken>");
//Pass your 36 character apiKey and apiToken as a parameter
//(visit https://voiceit.io/settings to see your credentials)
`;

const createUserCode = `
myVoiceIt.createUser((jsonResponse)=>{
  const userId = jsonResponse["userId"]; // Save generated userId to use later
});
`;

const createVideoEnrollmentCode = `
myVoiceIt.createUser((jsonResponse)=>{
  let userId = jsonResponse["userId"]; // Save generated userId to use later
  myVoiceIt.createVideoEnrollment({
    userId: userId,
    contentLanguage : "en-us",
    videoFilePath : "/Users/username/Downloads/enroll.mp4"
  },(jsonResponse)=>{
    console.log(jsonResponse);
  });
});
`;

const finalVideoVerificationCode = `
myVoiceIt.videoVerification({
  userId: userId,
  contentLanguage : "en-us",
  videoFilePath : "/Users/username/Downloads/verify.mp4"
},(jsonResponse)=>{
  if (jsonResponse["responseCode"] === "SUCC") {
    // Add behavior for when user is successfully authenticated
  } else {
    // Add behavior for when video verification failed
  }
});
`;

export default function MyFaceAndVoiceIdentifyMe() {
  return (
    <>
      <Box sx={{
        backgroundImage: `url(${MyFaceAndVoiceIdentifyMeCoverImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100% 100%',
        marginTop: '64px',
      }}
      >
        <Container sx={{
          width: '100vw',
          height: '66vw',
          // height: {
          //   xs: '300px', sm: '500px', md: '700px', lg: '100vh',
          // },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#FFFFFF',
        }}
        />
      </Box>
      <Box
        sx={{
          '@media (min-width: 600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 6 },
          pb: { xs: 6, md: 6, lg: 9 },
        }}
        >
          <Grid
            container
            spacing={3}
            justifyContent={{ md: 'center' }}
          >
            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                  color: '#000000',
                  ...responsiveFontSizes({
                    xs: 34, sm: 34, md: 34, lg: 40,
                  }),
                }}
              >
                My Voice and Face Identify Me
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Your guide to API 2.0.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  color: '#000000',
                  ...responsiveFontSizes({
                    xs: 28, sm: 28, md: 28, lg: 32,
                  }),
                }}
              >
                So what makes API 2.0 so special?
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                The ability to identify and verify using your face and voice…
              </Typography>
              <Typography>
                API 2.0 provides the ability to
                easily integrate multi-modal and multi-engine facial
                and voice Identification (1-to-n) and Verification (1-to-1).
                Identify or verify users with their face, voice, or face+voice!
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Where can I use a VoiceIt Developer Account?
              </Typography>
              <Typography>
                The better question is “Where can&#39;t you use it?”
                We&#39;ve already done the hard work for you.
                What you get is an easy-to-use RESTful API. But under the hood are
                all the complexities of a facial and voice biometrics powerhouse.
                This means that the sky&#39;s the limit.
                If something has either a camera, sound recording capabilities, or both,
                with access to the internet you can secure it with
                {' '}
                <Link component={RouterLink} to="/WhatIsQuantaKey" color="inherit" underline="always">
                  API 2.0
                </Link>
                .
                Most frequently, customers use API 2.0 as a part of a single-factor
                or 2-factor authentication system in the following platforms:
              </Typography>
              <List>
                <ListItem>
                  Interactive Voice Response (IVR,
                  {' '}
                  <Link target="_blank" href="https://github.com/voiceittech/VoiceIt2-Twilio-Demo">
                    Twilio
                  </Link>
                  )
                </ListItem>
                <ListItem>
                  Mobile apps (
                  <Link target="_blank" href="https://github.com/voiceittech/VoiceIt2-IosSDK">
                    iOS
                  </Link>
                  ,
                  {' '}
                  <Link target="_blank" href="https://github.com/voiceittech/VoiceIt2-AndroidSDK">
                    Android
                  </Link>
                  )
                </ListItem>
                <ListItem>
                  Web hosting providers (
                  <Link target="_blank" href="https://github.com/voiceittech/VoiceIt2-WebSDK">
                    Web Sites
                  </Link>
                  )
                </ListItem>
                <ListItem>
                  Internet Connected Devices (IoT)
                </ListItem>
              </List>

              <Typography>
                Don&#39;t have a Developer Account? Sign up
                {' '}
                <Link component={RouterLink} to="/contactUs" color="inherit" underline="always">
                  here
                </Link>
                !
              </Typography>

            </Grid>
            <Grid item xs={12} md={8}>

              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Getting started with API 2.0
              </Typography>

              <Typography>
                Below we will cover how to get started using the Verification feature.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Determine what biometrics you would like to use

              </Typography>

              <Typography>
                With API 2.0, we give you the ability to verify and
                identify users with their face, voice, or face+voice.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                <Link target="_blank" href="https://api.voiceit.io/#authentication" underline="always">
                  Authentication
                </Link>
              </Typography>

              <Typography>
                VoiceIt uses an API Key and Authorization
                Token to allow access to the API.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                <Link target="_blank" href="https://api.voiceit.io/#users" underline="always">
                  Users
                </Link>
              </Typography>

              <Typography>
                We have simplified the
                {' '}
                <span className="landingHighlightedWords">createUser</span>
                {' '}
                process
                by generating a unique 36 character alphanumeric
                {' '}
                <span className="landingHighlightedWords">userId</span>
                {' '}
                for each user.

                Make sure to save the
                {' '}
                <span className="landingHighlightedWords">userId</span>
                {' '}
                returned by this API call
                as it is needed for all future API calls related directly to this user profile.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                <Link target="_blank" href="https://api.voiceit.io/?javascript#enrollments" underline="always">
                  Enrollment
                </Link>
              </Typography>

              <Typography>
                Let&#39;s get into the details of enrolling with each modality.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                <Link target="_blank" href="https://api.voiceit.io/?javascript#create-voice-enrollment" underline="always">
                  Voice
                </Link>
              </Typography>

              <Typography>
                We recommend using
                {' '}
                <span className="landingHighlightedWords">createVoiceEnrollment</span>
                {' '}
                to capture at least 3 voiceprint phrases.
                During this on-boarding process you want
                to make sure the enrollments are of high-quality.
                To accomplish this you can use the
                {' '}
                <span className="landingHighlightedWords">textConfidence</span>
                {' '}
                field
                (of the JSON response) and check that it is 65% or higher.
                If
                {' '}
                <span className="landingHighlightedWords">textConfidence</span>
                {' '}
                is less than 65%, you would delete the enrollment
                associated with the given
                {' '}
                <span className="landingHighlightedWords">id</span>
                {' '}
                field (of the JSON response).
                Once you have high-quality enrollments, you are ready to verify the user.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                <Link target="_blank" href="https://api.voiceit.io/?javascript#create-face-enrollment" underline="always">
                  Face
                </Link>
              </Typography>

              <Typography>
                The recommended
                {' '}
                <span className="landingHighlightedWords">createFaceEnrollment</span>
                {' '}
                process is to capture a 2 second video of
                the user facing the camera with their
                face being prominent and centered in the video frame.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                <Link target="_blank" href="https://api.voiceit.io/?javascript#create-video-enrollment" underline="always">
                  Video (Face+Voice)
                </Link>
              </Typography>

              <Typography>
                We recommend using
                {' '}
                <span className="landingHighlightedWords">createVideoEnrollment</span>
                {' '}
                to capture 3 videos
                (each video 5 seconds in length) containing the user&#39;s face and speaking
                their voiceprint phrase. During this on-boarding process you want to
                make sure that the enrollments are of high-quality. To accomplish this,
                we use face detection and
                {' '}
                <span className="landingHighlightedWords">textConfidence</span>
                {' '}
                (part of the JSON response).
                Once you have high-quality enrollments, you are ready to verify the user.

              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  color: '#000000',
                  ...responsiveFontSizes({
                    xs: 28, sm: 28, md: 28, lg: 32,
                  }),
                }}
              >
                <Link target="_blank" href="https://api.voiceit.io/?javascript#verification" underline="always">
                  Verification
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>

              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                <Link target="_blank" href="https://api.voiceit.io/?javascript#verify-a-user-s-voice" underline="always">
                  Voice
                </Link>
              </Typography>

              <Typography>
                The
                {' '}
                <span className="landingHighlightedWords">voiceVerification</span>
                {' '}
                process is simple and straightforward.
                Once a user has a minimum of 3 voice enrollments with a voiceprint phrase,
                it can be used for verification. The JSON response contains a
                {' '}
                <span className="landingHighlightedWords">confidence</span>
                {' '}
                ,
                {' '}
                <span className="landingHighlightedWords">responseCode</span>
                {' '}
                , and
                {' '}
                <span className="landingHighlightedWords">textConfidence</span>
                {' '}
                .
                You can use these three items to determine a successful verification
                just like the enrollment process checks.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                <Link target="_blank" href="https://api.voiceit.io/?javascript#verify-a-user-s-face" underline="always">
                  Face
                </Link>
              </Typography>

              <Typography>
                The
                {' '}
                <span className="landingHighlightedWords">faceVerification</span>
                {' '}
                process is as easy as it gets.
                Capture a 2 second video of the user facing the camera
                with the face being prominent and centered in the video frame.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                <Link target="_blank" href="https://api.voiceit.io/?javascript#verify-a-user-s-voice-amp-face" underline="always">
                  Video (Face+Voice)
                </Link>
              </Typography>

              <Typography>
                The
                {' '}
                <span className="landingHighlightedWords">videoVerification</span>
                {' '}
                process is where multi-modal biometrics shine.
                Once a user has a minimum of 3 video enrollments with a voiceprint phrase,
                it can be used for verification.
              </Typography>
              <Typography>
                Capture a 5 second video containing the users
                face and voiceprint phrase to perform Video Verification.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Getting started is as easy as 1–2–3!
              </Typography>
              <MuiList>
                <MuiListItem>
                  1. Create a new User with createUser RESTful API call. This returns a new userId.
                </MuiListItem>
                <MuiListItem>
                  2. Enroll a User with Video Enrollments 3 times with the createVideoEnrollment
                  or
                  with the createVideoEnrollmentByUrl
                  RESTful API call. This creates a
                  new enrollment template for the specified user profile.
                </MuiListItem>
                <MuiListItem>
                  3. Verify a User with videoVerification or the videoVerificationByUrl
                  RESTful API call. This is used to verify the specified user profile.
                </MuiListItem>
              </MuiList>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                The API Parameters
              </Typography>

              <Typography>
                The VoiceIt API Parameters are flexible, powerful, and simple.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Voice Verification
              </Typography>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  py: 1,
                }}
              >
                <img src={VoiceVerificationParameters} alt="QuantaSafe" style={{ width: '100%' }} />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Face Verification
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  py: 1,
                }}
              >
                <img src={FaceVerificationParameters} alt="QuantaSafe" style={{ width: '100%' }} />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Video (Face+Voice) Verification
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  py: 1,
                }}
              >
                <img src={VideoVerificationParameters} alt="QuantaSafe" style={{ width: '100%' }} />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                A Video Verification Code Example
              </Typography>
              <Typography>
                The following code snippets use our
                {' '}
                <Link target="_blank" href="https://api.voiceit.io/?javascript" underline="always">
                  NodeJS Wrapper
                </Link>
                {' '}
                to call our RESTful API.

                First, initialize the NodeJS wrapper with your API Key and Token.
              </Typography>
              <BlogCodeSnippet code={videoVerificationCode} />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                Create a User:
              </Typography>
              <BlogCodeSnippet code={createUserCode} />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                Use the value of the user ID returned by
                {' '}
                <span className="landingHighlightedWords">createUser()</span>
                {' '}
                in order to create
                a new video enrollment for that user (create at least 3 enrollments per user):
              </Typography>
              <BlogCodeSnippet code={createVideoEnrollmentCode} />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                Finally, verify the user via
                {' '}
                <span className="landingHighlightedWords">videoVerification()</span>
                {' '}
                :
              </Typography>
              <BlogCodeSnippet code={finalVideoVerificationCode} />
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Where do you manage your integration?
              </Typography>
              <Typography>
                Everything you need to manage your integration is available in our Dashboard.
                Here are the main features:
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Voiceprint Phrases
              </Typography>
              <Typography>
                Here&#39;s where you manage all your custom phrases.
                For a detailed tutorial on the power of
                Voiceprint Phrases, you can check out
                {' '}
                <Link component={RouterLink} to="/blog/voicePrintPhrases">
                  this other post I wrote
                </Link>
                .
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  py: 1,
                }}
              >
                <img src={PhraseManagementGif} alt="QuantaSafe" style={{ width: '100%' }} />
              </Box>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Docs
              </Typography>
              <Typography>
                To access our API 2.0 documentation, please go to
                {' '}
                <Link target="_blank" href="api.voiceit.io.">
                  api.voiceit.io.
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                This is the motherlode of API documentation.
                No matter what language you prefer,
                we&#39;ve got all the wrappers and code snippets you need.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                We&#39;ve also got great resources on our
                {' '}
                <Link target="_blank" href="https://github.com/voiceittech">
                  GitHub
                </Link>
                {' '}
                page,
                such as our
                {' '}
                <Link target="_blank" href="https://github.com/voiceittech/VoiceIt2-IosSDK">
                  iOS SDK
                </Link>
                ,
                {' '}
                <Link target="_blank" href="https://github.com/voiceittech/VoiceIt2-AndroidSDK">
                  Android SDK
                </Link>
                , and
                {' '}
                <Link target="_blank" href="https://github.com/voiceittech/VoiceIt2-WebSDK">
                  Web SDK
                </Link>
                .
                These allow you to save time by copying code segments
                into your new or existing project.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  py: 1,
                }}
              >
                <img src={DocsGif} alt="DocsGif" style={{ width: '100%' }} />
              </Box>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                If you haven&#39;t already, get started now!
              </Typography>
            </Grid>

          </Grid>

        </Container>
      </Box>

    </>
  );
}
