/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  Link,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import DeepFakesVsSecurityCoverImage from '../../../assets/images/CoverImages/DeepFakesVsSecurityCoverImage.jpg';
import Engine1Graph from '../../../assets/images/Engine1.png';
import Engine2Graph from '../../../assets/images/Engine2.png';
import Engine3Graph from '../../../assets/images/Engine3.png';
import Engine4Graph from '../../../assets/images/Engine4.png';

import { responsiveFontSizes } from '../../../utils/getFontValue';
import { ReactPlayerStyle } from '../../Player/Player';

const title = 'The Voice Impersonation Showdown:';
const description = 'Deepfakes Meet Their Match in VoiceIt 2.0 Biometric Voice Verification';

export default function DeepFakesVsSecurity() {
  return (
    <>
      <Helmet>
        <meta property="og:title" key="og:title" content={title} />
        {/* <meta property="og:locale" key="og:locale" content="en_US" /> */}
        {/* <meta charSet="utf-8" /> */}
        {/* <meta property="og:type" key="og:type" content="website" /> */}
        <meta
          property="og:description"
          key="og:description"
          content={description}
        />
        <meta
          property="og:image"
          key="og:image"
          content="https://enquanta.io/images/DeepFakesVsSecurityCoverImage.jpg"
        />
      </Helmet>
      <Box sx={{
        backgroundImage: `url(${DeepFakesVsSecurityCoverImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100% 100%',
        marginTop: '64px',
      }}
      >
        <Container sx={{
          width: '100vw',
          height: '66vw',
          // height: {
          //   xs: '300px', sm: '500px', md: '700px', lg: '100vh',
          // },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#EEF1EF',
        }}
        />
      </Box>
      <Box
        sx={{
          '@media (min-width: 600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 6 },
          pb: { xs: 6, md: 6, lg: 9 },
        }}
        >
          <Grid
            container
            spacing={3}
            justifyContent={{ md: 'center' }}
          >
            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                  color: '#000000',
                  ...responsiveFontSizes({
                    xs: 34, sm: 34, md: 34, lg: 40,
                  }),
                }}
              >
                The Voice Impersonation Showdown:
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Deepfakes Meet Their Match in
                API 2.0 Biometric Voice Verification
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                The technological marvel that is deepfakes has been taking the world
                by storm. This advanced piece of engineering is capable of capturing
                existing voice snippets and skillfully cloning them into an almost
                perfect mimic. For proof of this, one only needs to witness Leonardo
                DiCaprio&apos;s speech bizarrely overdubbed by voices of Kim Kardashian and
                Joe Rogan among others (see external
                {' '}
                <Link target="_blank" href="https://www.youtube.com/watch?v=17_xLsqny9E" underline="always">
                  link
                </Link>
                ), or the comical Reddit clip
                where gym buffs become subjects of a nature documentary narrated in a
                faux David Attenborough style (see external
                {' '}
                <Link target="_blank" href="https://www.reddit.com/r/davidattenborough/comments/11gv4rp/i_used_ai_to_make_david_attenborough_narrate_this/" underline="always">
                  link
                </Link>
                ). Yet, as the
                fascination around this tech phenomenon grows, one can&apos;t help but ask:
                how does API 2.0 Biometric Verification, a modern day champion in
                cybersecurity, stand up against this uncanny ability?
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                In the following exploration, we&apos;ll delve into the inner workings of
                API 2.0&apos;s adjustable hyper parameters and thresholds, revealing how
                these features can be manipulated to bolster your system against
                potential deepfake breaches.

              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  color: '#000000',
                  ...responsiveFontSizes({
                    xs: 28, sm: 28, md: 28, lg: 32,
                  }),
                }}
              >
                Enter The Deepfake:
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                To create a deepfake clone of my voice, I enlisted the services of
                ElevenLabs (https://elevenlabs.io). To start off, I read a random
                article aloud and recorded the audio, which was then fed into their proprietary
                voice lab to instantaneously clone my voice (audio sample listed below).
                Emphasizing quality over quantity,
                ElevenLabs suggested that any recordings exceeding 5 minutes
                offer minimal enhancement and insisted that efforts be directed towards
                diminishing background noise and boosting the clarity of audio for optimal results.
              </Typography>
              <Box
                sx={{
                  // maxWidth: '100px'
                  display: 'grid',
                  backgroundColor: '#f9f9f9',
                  rowGap: { xs: 2, sm: 4 },
                  columnGap: { xs: 2, sm: 4 },
                  gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                  },
                  p: 3,
                  mt: 3,
                  borderRadius: '20px',
                }}
              >
                <ReactPlayerStyle
                  url="https://github.com/voiceittech/DeepFakeVsSecurityAssets/blob/main/AudioSample.mp3?raw=true"
                  controls
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload',
                      },
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                Following the cloning process, I produced 15 audio samples using my
                cloned voice for later biometric verification testing. To ensure
                consistency across all samples, the audio for both the cloned voice
                and subsequent test recordings of my real voice was recorded using
                the same equipment and under the same conditions.

              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  color: '#000000',
                  ...responsiveFontSizes({
                    xs: 28, sm: 28, md: 28, lg: 32,
                  }),
                }}
              >
                Unveiling API 2.0: Tweaking Hyper Parameters and Thresholds
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                API 2.0 harnesses the power of four AI Voice Engines. Upon enrollment in
                the system, users provide at least three voice recordings, generally
                reciting the same phrase. Once enrolled, verification
                involves the four engines each outputting a confidence score, which
                represents the degree of match between the voice in the incoming
                recording and the original enrollment recordings.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                The engines are equipped with hyper parameters that can modify how they
                compute these scores, though for the purposes of this experiment they
                were left at their default values. The main focus of our investigation
                centered on manipulating the engine thresholds. These thresholds act
                as a type of &apos;barrier&apos;, only allowing recordings that achieve a
                confidence score greater than the threshold to pass. Those that don&apos;t
                measure up are generally (with a few exceptions) denied.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                It should be highlighted that while this investigation utilized
                default values, in real-world applications during customer
                integration, a program termed &apos;Fine Tuning&apos; is deployed. This
                API 2.0 tool employs customer-specific data to adjust default settings,
                with the objective of generating the most accurate and optimal
                confidence scores.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  color: '#000000',
                  ...responsiveFontSizes({
                    xs: 28, sm: 28, md: 28, lg: 32,
                  }),
                }}
              >
                The Head-to-Head Test:
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                Our experiment comprised a face-off between 15 deepfake recordings and
                15 genuine voice recordings (labeled in the graphs below as “real”).
                Each recording was assessed by the four AI Engines.
                We included the two highest scoring deepfake recordings
                below as well as two genuine recordings for you to compare:
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  display: 'grid',
                  rowGap: { xs: 2, sm: 4 },
                  columnGap: { xs: 2, sm: 4 },
                  gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                  },
                  p: 3,
                }}
              >
                <Box
                  sx={{
                    display: 'grid',
                    rowGap: { xs: 2, sm: 4 },
                    columnGap: { xs: 2, sm: 4 },
                    gridTemplateColumns: {
                      xs: 'repeat(1, 1fr)',
                    },
                    p: 3,
                    backgroundColor: '#f9f9f9',
                    borderRadius: '20px',
                  }}
                >
                  <Typography sx={{ textAlign: 'center', fontWeight: 'bold', color: '#000000' }}>Fake</Typography>
                  <ReactPlayerStyle
                    url="https://github.com/voiceittech/DeepFakeVsSecurityAssets/blob/main/DeepFake4.mp3?raw=true"
                    controls
                    config={{
                      file: {
                        attributes: {
                          controlsList: 'nodownload',
                        },
                      },
                    }}
                  />
                  <ReactPlayerStyle
                    url="https://github.com/voiceittech/DeepFakeVsSecurityAssets/blob/main/DeepFake7.mp3?raw=true"
                    controls
                    config={{
                      file: {
                        attributes: {
                          controlsList: 'nodownload',
                        },
                      },
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: 'grid',
                    backgroundColor: '#f9f9f9',
                    rowGap: { xs: 2, sm: 4 },
                    columnGap: { xs: 2, sm: 4 },
                    gridTemplateColumns: {
                      xs: 'repeat(1, 1fr)',
                    },
                    p: 3,
                    borderRadius: '20px',
                  }}
                >
                  <Typography sx={{ textAlign: 'center', fontWeight: 'bold', color: '#000000' }}>Real</Typography>
                  <ReactPlayerStyle
                    url="https://github.com/voiceittech/DeepFakeVsSecurityAssets/blob/main/Real1.mp3?raw=true"
                    controls
                    config={{
                      file: {
                        attributes: {
                          controlsList: 'nodownload',
                        },
                      },
                    }}
                  />
                  <ReactPlayerStyle
                    url="https://github.com/voiceittech/DeepFakeVsSecurityAssets/blob/main/Real2.mp3?raw=true"
                    controls
                    config={{
                      file: {
                        attributes: {
                          controlsList: 'nodownload',
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                The overall results are as follows:
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>

              <Typography sx={{ textAlign: 'center', fontWeight: 'bold', color: '#000000' }}>
                Engine 1
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  py: 1,
                }}
              >
                <img src={Engine1Graph} alt="QuantaSafe" style={{ width: '100%' }} />
              </Box>
            </Grid>

            <Grid item xs={12} md={8}>

              <Typography sx={{ textAlign: 'center', fontWeight: 'bold', color: '#000000' }}>
                Engine 3
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  py: 1,
                }}
              >
                <img src={Engine3Graph} alt="QuantaSafe" style={{ width: '100%' }} />
              </Box>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                Engines 1 and 3 showed a clear distinction between the real and
                deepfake voices. The
                {' '}
                <span style={{ color: '#009DDC', fontWeight: 'bold' }}>genuine</span>
                {' '}
                recordings scored around
                {' '}
                <span style={{ color: '#009DDC', fontWeight: 'bold' }}>80</span>
                {' '}
                on Engine 1
                and
                {' '}
                <span style={{ color: '#009DDC', fontWeight: 'bold' }}>91</span>
                {' '}
                on Engine 3, while their
                {' '}
                <span style={{ color: '#422A76', fontWeight: 'bold' }}>deepfake</span>
                {' '}
                counterparts lagged with
                scores around
                {' '}
                <span style={{ color: '#422A76', fontWeight: 'bold' }}>21</span>
                {' '}
                and
                {' '}
                <span style={{ color: '#422A76', fontWeight: 'bold' }}>45</span>
                {' '}
                respectively. Accordingly, the confidence
                thresholds for Engines 1 and 3 were adjusted to 52 and 91, ensuring
                that the real voices passed the test and the deepfakes were weeded
                out.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>

              <Typography sx={{ textAlign: 'center', fontWeight: 'bold', color: '#000000' }}>
                Engine 2
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  py: 1,
                }}
              >
                <img src={Engine2Graph} alt="QuantaSafe" style={{ width: '100%' }} />
              </Box>
            </Grid>

            <Grid item xs={12} md={8}>

              <Typography sx={{ textAlign: 'center', fontWeight: 'bold', color: '#000000' }}>
                Engine 4
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  py: 1,
                }}
              >
                <img src={Engine4Graph} alt="QuantaSafe" style={{ width: '100%' }} />
              </Box>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                Engines 2 and 4, however, presented a closer battle. For Engine 2,
                {' '}
                <span style={{ color: '#422A76', fontWeight: 'bold' }}>deepfake</span>
                {' '}
                scores ranged between
                {' '}
                <span style={{ color: '#422A76', fontWeight: 'bold' }}>42-50</span>
                {' '}
                , with
                {' '}
                <span style={{ color: '#009DDC', fontWeight: 'bold' }}>real</span>
                {' '}
                voices achieving
                scores of
                {' '}
                <span style={{ color: '#009DDC', fontWeight: 'bold' }}>44-58</span>
                {' '}
                . For Engine 4,
                {' '}
                <span style={{ color: '#422A76', fontWeight: 'bold' }}>deepfakes</span>
                {' '}
                managed to reach a score of
                {' '}
                <span style={{ color: '#422A76', fontWeight: 'bold' }}>97</span>
                {' '}
                , while
                {' '}
                <span style={{ color: '#009DDC', fontWeight: 'bold' }}>real</span>
                {' '}
                voices reached out to
                {' '}
                <span style={{ color: '#009DDC', fontWeight: 'bold' }}>99.03</span>
                {' '}
                . Based on these
                findings, thresholds for Engines 2 and 4 were set to 55 and 97.1
                respectively, enabling real recordings to pass and deepfakes to fail.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                To further validate these settings, the experiment was repeated with a
                fresh batch of 15 recordings from both categories. This time, 14 out
                of 15 genuine recordings were successfully
                validated, while all the deepfakes were rejected.
                Even though one genuine recording failed to pass, this &apos;false negative&apos;
                was deemed acceptable, as ensuring zero &apos;false positives&apos;
                is paramount to maintaining a robust and secure system.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  color: '#000000',
                  ...responsiveFontSizes({
                    xs: 28, sm: 28, md: 28, lg: 32,
                  }),
                }}
              >
                Conclusion:
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                While deepfakes score ok on Engines 2 and 4, they struggle on Engines 1 and 3.
                The scores, although high, do not surpass those of genuine recordings.
                Modifying API 2.0 thresholds can adjust your security to ward off potential
                deepfake penetrations. During this experiment, we adhered to the default hyper
                parameters and observed one false negative and zero false positives. However,
                during the customer onboarding process, we employ Fine Tuning on customer data
                to enhance engine performance, thereby minimizing false positives and negatives
                even further.
              </Typography>
            </Grid>
          </Grid>

        </Container>
      </Box>

    </>
  );
}
