/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import {
  Typography,
  Grid,
  Paper,
  Stack,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { responsiveFontSizes } from '../../utils/getFontValue'; // Import using relative path

export default function StartOurCoPilotBox() {
  const navigate = useNavigate();

  return (
    <Grid
      item
      xs={12}
      sx={{
        mt: {
          xs: 0, sm: 0, md: 1, lg: 1,
        },
        mb: 3,
      }}
    >
      <Paper sx={{
        borderRadius: 3,
        borderStyle: 'solid',
        borderWidth: '3px',
        borderColor: '#505050',
        // borderWidth: { sx: '3px', md: '5px' },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        py: 7,
        ':hover': {
          boxShadow: 20, // theme.shadows[20]s
          backgroundColor: '#F1F3F1',
        },
      }}
      >
        <Stack sx={{
          justifyContent: 'center',
          alignItems: 'center',
          rowGap: 4,
        }}
        >
          <Typography
            variant="h3"
            sx={{
              ...responsiveFontSizes({
                xs: 24, sm: 32, md: 41, lg: 41,
              }),
              color: '#000000',
            }}
          >
            Start our Co-Pilot Program!
          </Typography>
          <Typography sx={{
            color: 'text.secondary',
            fontWeight: 'bold',
            ...responsiveFontSizes({
              xs: 18, sm: 22, md: 28, lg: 28,
            }),
            mb: 1,
          }}
          >
            Schedule an introductory meeting
          </Typography>
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            loading={false}
            sx={{
              mx: { xs: 'auto !important', md: 'unset !important' },
              backgroundColor: '#000000',
              p: { xs: 1, sm: 2, md: 3.5 },
              ...responsiveFontSizes({
                xs: 16, sm: 20, md: 24, lg: 24,
              }),
            }}
            onClick={() => {
              navigate('/contactUs');
            }}
          >
            Start Today
          </LoadingButton>
        </Stack>
      </Paper>

    </Grid>
  );
}
