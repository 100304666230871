/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  Link,
} from '@mui/material';
import TwoFADiagram from '../../../assets/images/TwoFADiagram.png';
import TwoFaCoverImage from '../../../assets/images/CoverImages/TwoFaCoverImage.jpg';

import { responsiveFontSizes } from '../../../utils/getFontValue';

export default function TwoFA() {
  return (
    <>
      <Box sx={{
        backgroundImage: `url(${TwoFaCoverImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100% 100%',
        marginTop: '64px',
      }}
      >
        <Container sx={{
          width: '100vw',
          height: '66vw',
          // height: {
          //   xs: '300px', sm: '500px', md: '700px', lg: '100vh',
          // },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#EEF1EF',
        }}
        />
      </Box>
      <Box
        sx={{
          '@media (min-width: 600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 6 },
          pb: { xs: 6, md: 6, lg: 9 },
        }}
        >
          <Grid
            container
            spacing={3}
            justifyContent={{ md: 'center' }}
          >
            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                  color: '#000000',
                  ...responsiveFontSizes({
                    xs: 34, sm: 34, md: 34, lg: 40,
                  }),
                }}
              >
                2FA — Two Factor Antiquated
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Why you should stop using SMS 2FA today.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                SMS text messages have been
                the de facto standard for 2FA (two factor authentication) for years.
                Due to a vulnerability in SS7 protocol, it is about as safe as public Wi-Fi.
                A recent example from cryptocurrency exchange
                {' '}
                <Link target="_blank" href="https://medium.com/@coinbase">Coinbase</Link>
                {' '}
                is all you need. According to a recent TechCrunch
                {' '}
                <Link
                  target="_blank"
                  href="https://techcrunch.com/2017/09/18/ss7-coinbase-bitcoin-hack-2fa-vulnerable/"
                >
                  Coinbase
                </Link>
                ,
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{ fontStyle: 'italic' }}>
                After identifying the Gmail account associated with a Coinbase
                account, they were able to use the well-known
                {' '}
                <Link
                  target="_blank"
                  href="https://www.wired.com/2017/05/fix-ss7-two-factor-authentication-bank-accounts/"
                >
                  security holes in Signaling System
                  7 (SS7)
                </Link>
                {' '}
                — an international telecom protocol that allows phone networks to route
                texts and calls between users — to intercept the SMS-based verification code to
                commandeer it, theoretically draining all of the cryptocurrency stored within.
                All the researchers needed was a name,
                a phone number and an educated guess about a user’s Gmail account
              </Typography>

            </Grid>
            <Grid item xs={12} md={8}>

              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Voice Biometric 2FA
              </Typography>
              <Typography>
                With VoiceIt’s RESTful API,
                {' '}
                <Link target="_blank" href="https://medium.com/@coinbase">Coinbase</Link>
                {' '}
                has the ability to
                implement a truly secure voice biometric based 2FA.
                A simplified version of the workflow is detailed below:

              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  py: 1,
                }}
              >
                <img src={TwoFADiagram} alt="QuantaSafe" style={{ width: '90%' }} />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                There are many potential options for how 2FA voice biometrics
                can be implemented in a financial scenario such as
                Coinbase
                :
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                When users log-in via app or website
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                Verifying transactions
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                Verifying account changes
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                To verify their identity, all a customer has to do is speak a given phrase,
                and voila, they are in. No more remembering passwords,
                trusting the “security” of SMS messages, or using your
                birthday and dogs name to get access to your account.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                Have any comments? Would you like to see this implemented?
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                Drop me a message at
                {' '}
                <Link href="mailto:tucker@voiceit.io">tucker@voiceit.io</Link>
                .
              </Typography>
            </Grid>

          </Grid>

        </Container>
      </Box>

    </>
  );
}
