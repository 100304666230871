import React from 'react';
import { Box } from '@mui/material';
import WhatIsQuantaKey from './WhatIsQuantaKey/WhatIsQuantaKey';

export default function Home() {
  return (
    <Box>
      <WhatIsQuantaKey />
    </Box>
  );
}
