/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  List as MuiList,
  ListItem as MuiListItem,
  Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeletePhrase from '../../../assets/images/DeletePhrase.gif';
// import ResubmitPhrase from '../../../assets/images/ResubmitPhrase.gif';
import PhraseManagement from '../../../assets/images/RequestNewPhrase.gif';
import VoicePrintPhrasesCoverImage from '../../../assets/images/CoverImages/VoicePrintPhrasesCoverImage.jpg';

import { responsiveFontSizes } from '../../../utils/getFontValue';

const List = styled(MuiList)(() => ({
  // display: 'list-item',
  listStyleType: 'disc',
}));

const ListItem = styled(MuiListItem)(() => ({
  display: 'list-item',
  listStyleType: 'disc',
  marginLeft: '24px',
  pl: 1,
}));

export default function VoicePrintPhrases() {
  return (
    <>
      <Box sx={{
        backgroundImage: `url(${VoicePrintPhrasesCoverImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100% 100%',
        marginTop: '64px',
      }}
      >
        <Container sx={{
          width: '100vw',
          height: '66vw',
          // height: {
          //   xs: '300px', sm: '500px', md: '700px', lg: '100vh',
          // },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#EEF1EF',
        }}
        />
      </Box>
      <Box
        sx={{
          '@media (min-width: 600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 6 },
          pb: { xs: 6, md: 6, lg: 9 },
        }}
        >
          <Grid
            container
            spacing={3}
            justifyContent={{ md: 'center' }}
          >
            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                  color: '#000000',
                  ...responsiveFontSizes({
                    xs: 34, sm: 34, md: 34, lg: 40,
                  }),
                }}
              >
                Voiceprint Phrases
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                The peanut butter to API 2.0’s biometric engine jelly.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                What are voiceprint phrases?
              </Typography>
              <Typography>
                Simply put, these are unique phrases that have been created for our API.
                They’re used with our speech-to-text engines,
                in addition to the text-dependent voice biometric engines,
                to uphold the highest level of security.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                What makes a good voiceprint phrase?
              </Typography>
              <Typography>
                Contrary to popular belief,
                ‘my voice is my password’ is actually not a secure phrase.
                Due to the repeating sounds and lengths, it doesn’t meet our standards.
                We use the
                {' '}
                <Link
                  target="_blank"
                  href="https://en.wikipedia.org/wiki/International_Phonetic_Alphabet"
                >
                  International Phonetic Alphabet (IPA)
                </Link>
                {' '}
                as a guide.
                Thus, approved phrases contain enough IPA
                phonetic data to allow for an accurate voiceprint.
                Remember that your phrase needs a minimum of 10
                syllables and should have no repeating IPA phonemes. Examples include:
              </Typography>
              <List>
                <ListItem>
                  Never forget tomorrow is a new day
                </ListItem>
                <ListItem>
                  Today is a nice day to go for a walk
                </ListItem>
              </List>

            </Grid>
            <Grid item xs={12} md={8}>

              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                How do I request a voiceprint phrase?
              </Typography>

              <Typography>
                If you want to create a custom phrase,
                you can request it via the Voiceprint Phrases section of
                your API 2.0 Dashboard. Use these easy steps:
              </Typography>
              <MuiList>
                <MuiListItem>
                  1. Log in to the Dashboard.
                </MuiListItem>
                <MuiListItem>
                  2. Navigate to Voiceprint Phrases.
                </MuiListItem>
                <MuiListItem>
                  3. Type your phrase in the Request New Phrase section.
                </MuiListItem>
              </MuiList>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  py: 1,
                }}
              >
                <img src={PhraseManagement} alt="QuantaSafe" style={{ width: '100%' }} />
              </Box>
              <MuiList>
                <MuiListItem>
                  4. Make sure to select the proper content language from the dropdown menu.
                  You need to have a phrase for each content language that you wish to use.
                </MuiListItem>
              </MuiList>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                How do I manage my voiceprint phrases?
              </Typography>

              <Typography>
                You can also see all of your approved phrases
                in the Voiceprint Phrases section of the Dashboard.
                Simply scroll down to Your Phrases to review them:
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>

              <Typography>
                If you need to, you can easily delete any phrase you want to stop using.
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  py: 1,
                }}
              >
                <img src={DeletePhrase} alt="QuantaSafe" style={{ width: '100%' }} />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>

              <Typography>
                That’s a quick overview of voiceprint phrases.
                I hope it helped and inspired you.
                Now get back out there and create some phrases of your own!
              </Typography>
            </Grid>
          </Grid>

        </Container>
      </Box>

    </>
  );
}
