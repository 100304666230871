export default function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export const breakpoints = {
  xs: 0,
  sm: 600,
  smmd: 750,
  md: 900,
  lg: 1200,
  xl: 1536,
};
