/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box,
  Typography,
  Stack,
  Container,
  Grid,
  Paper,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DownloadIcon from '@iconify/icons-carbon/download';

import { responsiveFontSizes } from '../../utils/getFontValue';
import BrandLogo from '../../assets/images/voiceit-logo-wordmark-default.png';
import MonoBrandLogo from '../../assets/images/voiceit-logo-wordmark-monotone.png';
import HexaLogo from '../../assets/images/HexaLogo.png';

import EnQuantaLogoBrandingWrongColors from '../../assets/images/EnQuantaLogoBrandingWrongColors.png';
import EnQuantaLogoBrandingWrongCropping from '../../assets/images/EnQuantaLogoBrandingWrongCropping.png';
import EnQuantaLogoBrandingWrongPasting from '../../assets/images/EnQuantaLogoBrandingWrongPasting.png';
import EnQuantaLogoBrandingWrongScaling from '../../assets/images/EnQuantaLogoBrandingWrongScaling.png';
import LogoCard from './LogoCard';
import ColorCard from './ColorCard';
import Iconify from '../Iconify';

const brandInfo = [
  {
    name: 'regular',
    image: BrandLogo,
  },
  {
    name: 'black',
    image: MonoBrandLogo,
  },
  {
    name: 'no text',
    image: HexaLogo,
    ratio: '9/21',
  },
  {
    name: 'EnQuantaLogoBrandingWrongColors',
    image: EnQuantaLogoBrandingWrongColors,
  },
  {
    name: 'EnQuantaLogoBrandingWrongCropping',
    image: EnQuantaLogoBrandingWrongCropping,
  },
  {
    name: 'EnQuantaLogoBrandingWrongPasting',
    image: EnQuantaLogoBrandingWrongPasting,
  },
  {
    name: 'EnQuantaLogoBrandingWrongScaling',
    image: EnQuantaLogoBrandingWrongScaling,
  },
];

const colors = [
  {
    name: 'Black',
    color: '000000',
    textIsBlack: false,
  },
  {
    name: 'Xanthous',
    color: 'FCBC3D',
    textIsBlack: true,
  },
  {
    name: 'Davy\'s Gray',
    color: '505050',
    textIsBlack: false,
  },
  {
    name: 'Cambridge Blue',
    color: '75B09C',
    textIsBlack: false,
  },
  {
    name: 'Lavender Blush',
    color: 'EEE5E9',
    textIsBlack: true,
  },
  {
    name: 'Anti-flash White',
    color: 'EFEFEF',
    textIsBlack: true,
  },
];

export default function Branding() {
  return (
    <Box sx={{
      height: 'auto',
      minHeight: { md: '100vh' },
      '@media (min-width: 600px)': {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
      backgroundColor: '#EEF1EF',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '64px',
    }}
    >
      <Container sx={{
        pt: { xs: 6, md: 6, lg: 6 },
        pb: { xs: 6, md: 6, lg: 6 },
      }}
      >
        <Stack
          spacing={3}
          sx={{
            maxWidth: { xs: 480, lg: 580 },
            textAlign: 'center',
            mx: 'auto',
            mb: { xs: 6 },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              ...responsiveFontSizes({
                xs: 28, sm: 30, md: 36, lg: 48,
              }),
            }}
          >
            Brand Resources & Guidelines

          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            We want to make the correct version of our logo,
            colors, and other graphics easily accessible for your needs.
          </Typography>
        </Stack>

        <Grid container columnSpacing={4} rowSpacing={6}>
          <Grid item xs={12} md={12}>
            <Stack
              spacing={3}
              sx={{
                textAlign: 'center',
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  ...responsiveFontSizes({
                    xs: 28, sm: 30, md: 36, lg: 48,
                  }),
                }}
              >
                Logo

              </Typography>

              <Typography sx={{ color: 'text.secondary' }}>
                These are official VoiceIt logos that you need to include on your website,
                in your mobile application, or in printed material.
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  rowGap: { xs: 4, md: 5 },
                  columnGap: 4,
                  pr: { xs: 0, lg: 1.5 },
                  gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    md: 'repeat(2, 1fr)',
                  },
                }}
              >
                <LogoCard sx={{ backgroundColor: '#000000', px: 2 }} LogoInfo={brandInfo[0]} />
                <LogoCard sx={{ px: 2 }} LogoInfo={brandInfo[1]} />
                {/* <LogoCard LogoInfo={brandInfo[2]} /> */}
              </Box>
            </Stack>
            {/* <Stack
              spacing={3}
              sx={{
                textAlign: 'center',
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  ...responsiveFontSizes({
                    xs: 28, sm: 30, md: 36, lg: 48,
                  }),
                }}
              >
                Dont

              </Typography>

              <Typography sx={{ color: 'text.secondary' }}>
                These are official VoiceIt logos that you need to include on your website,
                in your mobile application, or in printed material.
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  rowGap: { xs: 4, md: 5 },
                  columnGap: 4,
                  pr: { xs: 0, lg: 1.5 },
                  gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    md: 'repeat(2, 1fr)',
                  },
                }}
              >
                <LogoCard LogoInfo={brandInfo[3]} />
                <LogoCard LogoInfo={brandInfo[4]} />
                <LogoCard LogoInfo={brandInfo[5]} />
                <LogoCard LogoInfo={brandInfo[6]} />

              </Box>
            </Stack> */}
          </Grid>

          <Grid item xs={12} md={12}>
            <Stack
              spacing={3}
              sx={{
                textAlign: 'center',
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  ...responsiveFontSizes({
                    xs: 28, sm: 30, md: 36, lg: 48,
                  }),
                }}
              >
                Color Scheme

              </Typography>

              <Typography sx={{ color: 'text.secondary' }}>
                Color plays a big role in brand identity.
                Please make sure to use the exact color values below for our logos,
                resources and when designing VoiceIt themed articles.
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  rowGap: { xs: 4, md: 5 },
                  columnGap: 4,
                  pr: { xs: 0, lg: 1.5 },
                  gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    md: 'repeat(2, 1fr)',
                  },
                }}
              >
                {colors.map((color) => (<ColorCard colorInfo={color} key={color.color} />))}
              </Box>
            </Stack>

          </Grid>

          {/* <Grid item xs={12} md={12}>
            <Stack
              spacing={3}
              sx={{
                textAlign: 'center',
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  ...responsiveFontSizes({
                    xs: 28, sm: 30, md: 36, lg: 48,
                  }),
                }}
              >
                Resources
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                For best results, use our original assets to maintain quality rendering.
                This file includes our fonts and icons.
              </Typography>
              <Stack
                spacing={3}
                direction="row"
                justifyContent="space-around"
              >
                <LoadingButton
                  size="large"
                  variant="contained"
                  // loading={isLoading}
                  sx={{
                    mx: { xs: 'auto !important', md: 'unset !important' },
                    backgroundColor: '#F17300',
                  }}
                  endIcon={<Iconify icon={DownloadIcon} sx={{ mr: 1, width: 24, height: 24 }} />}
                >
                  Original Assets
                </LoadingButton>
              </Stack>

            </Stack>

          </Grid> */}
        </Grid>

      </Container>
    </Box>
  );
}
