/* eslint-disable react/prop-types */
import {
  Box, Container, Dialog, DialogContent,
  DialogTitle, Divider, Grid,
  IconButton, Link, Paper, Snackbar, SnackbarContent, Stack, TextField, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import { m } from 'framer-motion';
import { styled } from '@mui/material/styles';
// import { PopupModal } from "react-calendly";
import close from '@iconify/icons-carbon/close';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import githubIcon from '@iconify/icons-carbon/logo-github';
// import twitterIcon from '@iconify/icons-carbon/logo-twitter';
import youtubeIcon from '@iconify/icons-carbon/logo-youtube';
import checkMarkIcon from '@iconify/icons-carbon/checkmark-filled';
import { Icon as IconifyIcon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import Logo from '../../assets/images/VoiceItBlack.png';
import { pxToRem, responsiveFontSizes } from '../../utils/getFontValue';
import Iconify from '../Iconify';

// TODO: change all of the section text to variables
export const productsSectionHeader = 'Products';
export const newsletterText = 'Biometric Access Protection';

export default function Footer() {
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  return (
    <Box sx={{
      backgroundColor: '#000000',
      color: '#EEF1EF',
      '@media (min-width: 600px)': {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
    }}
    >
      <Container sx={{
        pt: { xs: 4, md: 4, lg: 6 }, pb: { xs: 4, md: 10, lg: 12 },
      }}
      >
        <Grid container spacing={1} justifyContent={{ md: 'space-between' }}>
          <Grid item xs={12} md={4}>
            <Stack alignItems="flex-start" spacing={3}>
              <img src={Logo} style={{ width: '120px', paddingBottom: '10px' }} alt="API 2.0" />
            </Stack>

            <Stack spacing={0}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: pxToRem(15),
                  fontWeight: 'bold',
                }}
              >
                API 2.0

              </Typography>
              <Typography variant="body3" sx={{ color: 'text.white', fontSize: pxToRem(14) }}>
                {newsletterText}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} md={2} lg={2} />

          <Grid item xs={7} md={2} lg={1}>
            <Stack alignItems="flex-start">
              <Typography
                variant="h5"
                sx={{
                  ...responsiveFontSizes({
                    xs: 18, sm: 18, md: 18, lg: 18,
                  }),
                  mt: { xs: 1, md: 0 },
                }}
              >
                Explore

              </Typography>
              <Link sx={{ color: 'text.white' }} component={RouterLink} to="/documentation">
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: pxToRem(15),
                  }}
                >
                  Documentation
                </Typography>

              </Link>
              <Link sx={{ color: 'text.white' }} component={RouterLink} to="/blog">
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: pxToRem(15),
                  }}
                >
                  Blog
                </Typography>

              </Link>
              <Link sx={{ color: 'text.white' }} component={RouterLink} to="/branding">
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: pxToRem(15),
                  }}
                >
                  Brand Guidelines
                </Typography>

              </Link>
            </Stack>
          </Grid>
          <Grid item xs={5} md={2} lg={2}>
            <Stack alignItems="flex-start">
              <Typography
                variant="h5"
                sx={{
                  ...responsiveFontSizes({
                    xs: 18, sm: 18, md: 18, lg: 18,
                  }),

                }}
              >
                Company

              </Typography>
              <Link sx={{ color: 'text.white' }} component={RouterLink} to="/about">
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: pxToRem(15),
                  }}
                >
                  About
                </Typography>

              </Link>
              <Link sx={{ color: 'text.white' }} href="mailto:support@voiceit.io">
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: pxToRem(15),
                  }}
                >
                  Support
                </Typography>

              </Link>
              <Link sx={{ color: 'text.white' }} component={RouterLink} to="/privacyPolicy">
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: pxToRem(15),
                  }}
                >
                  Privacy Policy
                </Typography>

              </Link>
              <Link sx={{ color: 'text.white' }} component={RouterLink} to="/termsAndConditions">
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: pxToRem(15),
                  }}
                >
                  Terms / Conditions
                </Typography>

              </Link>
              <Link sx={{ color: 'text.white' }} component={RouterLink} to="/contactUs">
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: pxToRem(15),
                  }}
                >
                  Contact
                </Typography>

              </Link>
            </Stack>
          </Grid>

        </Grid>
      </Container>

      <Divider />

      <Container>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={2.5}
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
          sx={{ py: 2, textAlign: 'center' }}
        >
          <Typography variant="body3">
            © 2024 VoiceIt
          </Typography>
          <Stack direction="row" spacing={3} justifyContent="center">
            <IconButton variant="text" onClick={() => window.open('https://github.com/voiceittech', '_blank')}>
              <IconifyIcon style={{ color: '#EEF1EF' }} icon={githubIcon} />
            </IconButton>
            {/* <IconButton variant="text" onClick={() => window.open('https://twitter.com/EnQuantaNews', '_blank')}>
              <IconifyIcon style={{ color: '#EEF1EF' }} icon={twitterIcon} />
            </IconButton> */}
            <IconButton variant="text" onClick={() => window.open('https://www.youtube.com/@Voiceittechus', '_blank')}>
              <IconifyIcon style={{ color: '#EEF1EF' }} icon={youtubeIcon} />
            </IconButton>
          </Stack>
        </Stack>
      </Container>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
        message="Subscription Request Sent!"
      >
        <SnackbarContent
          style={{
            backgroundColor: '#F17300',
            boxShadow: 'none',
            color: '#EEF1EF',
          }}
          message="Subscription Request Sent!"
        />
      </Snackbar>
      <Dialog
        open={openDialog}
        // onClose={}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Iconify
            aria-label="close"
            icon={close}
            onClick={() => { setOpenDialog(false); }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          />
        </DialogTitle>
        <DialogContent>
          <MailchimpSubscribe
            url="https://enquanta.us10.list-manage.com/subscribe/post?u=3dd68700088d0d7861280fd49&id=e40913e116"
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            )}
          />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions> */}
      </Dialog>
    </Box>
  );
}

function CustomForm({ status, message, onValidated }) {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  let disableButton = false;
  let isSending = false;
  let isError = false;
  let isSuccess = false;
  let buttonText = 'Subscribe';
  if (status === 'sending') {
    disableButton = true;
    isSending = true;
    buttonText = 'Sending';
  } else if (status === 'error') {
    disableButton = false;
    isError = true;
  } else if (status === 'success') {
    disableButton = true;
    isSuccess = true;
  }
  if (isSuccess) {
    return (
      <Paper
        component={m.div}
        whileHover="hover"
        sx={{
          borderRadius: 2,
          overflow: 'hidden',
          p: 2,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Iconify icon={checkMarkIcon} sx={{ fontSize: '50px', color: '#009DDC', my: 2 }} />
          <Typography sx={{ color: 'text.secondary' }}>
            {message}
          </Typography>
        </Box>
      </Paper>
    );
  }

  return (
    <Paper
      component={m.div}
      whileHover="hover"
      sx={{
        borderRadius: 2,
        overflow: 'hidden',
        p: 2,
      }}
    >
      <Stack
        spacing={2}
        sx={{
          mb: isError ? 3 : 5,
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: '#000000',
            ...responsiveFontSizes({
              xs: 24, sm: 24, md: 30, lg: 30,
            }),
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          Quantum Newsletter

        </Typography>

        <Typography sx={{
          color: 'text.secondary',
          ...responsiveFontSizes({
            xs: 16, sm: 16, md: 18, lg: 18,
          }),
        }}
        >
          Sign up to get updates
        </Typography>

        {!isSuccess && (
          <>
            <HigherTextInput
              fullWidth
              label="Email"
              disabled={isSending}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <HigherTextInput
              fullWidth
              disabled={isSending}
              label="First Name"
              value={firstName}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
            />
            <HigherTextInput
              fullWidth
              disabled={isSending}
              label="Last Name"
              value={lastName}
              onChange={(event) => {
                setLastName(event.target.value);
              }}
            />
          </>
        )}
        {isError && (
          <Typography sx={{
            color: '#DC4D00',
            ...responsiveFontSizes({
              xs: 14, sm: 14, md: 14, lg: 14,
            }),
            maxWidth: '300px',
          }}
          >
            {message}
          </Typography>
        )}
      </Stack>

      {!isSuccess && (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSending}
          disabled={disableButton}
          sx={{
            mx: { xs: 'auto !important', md: 'unset !important' },
            backgroundColor: '#000000',
          }}
          onClick={() => {
            onValidated({
              EMAIL: email,
              MERGE1: firstName,
              MERGE2: lastName,
            });
          }}
        >
          {buttonText}
        </LoadingButton>
      )}

    </Paper>
  );
}

const HigherTextInput = styled(TextField)(() => ({
  '& .MuiFilledInput-input': {
    minHeight: '1.6em',
  },
}));
