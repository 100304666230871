/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import vsLight from 'prism-react-renderer/themes/vsLight';

export default function BlogCodeSnippet({ code }) {
  return (
    <Highlight {...defaultProps} code={code} language="js" theme={vsLight}>
      {({
        className, style, tokens, getLineProps, getTokenProps,
      }) => (
        <pre
          className={className}
          style={{
            ...style, padding: '0px 16px', borderRadius: '20px', overflow: 'scroll', fontSize: '14px',
          }}
        >
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
}
