/* eslint-disable react/prop-types */
import React from 'react';
import {
  Typography,
  Stack,
  Paper,
} from '@mui/material';
import { responsiveFontSizes } from '../../utils/getFontValue';

export default function ColorCard({ colorInfo }) {
  const {
    name, color, textIsBlack,
  } = colorInfo;
  const textColor = textIsBlack ? '#000000' : '#EEF1EF';
  return (
    <Paper
      variant="outlined"
      elevation={0}
      sx={{
        borderRadius: 2,
        overflow: 'hidden',
        ':hover': {
          boxShadow: 20, // theme.shadows[20]
        },
        backgroundColor: `#${color}`,
      }}
    >
      <Stack
        direction="row"
        spacing={3}
        sx={{
          p: 3,
          height: '200px',
        }}
      >

        <Stack spacing={1} justifyContent="flex-end" flexGrow={1}>
          <Typography
            variant="h6"
            sx={{
              ...responsiveFontSizes({
                xs: 26, sm: 26, md: 26, lg: 30,
              }),
              color: textColor,
              textAlign: 'left',
            }}
          >
            {color}
          </Typography>

          <Typography
            variant="body2"
            persistent
            sx={{
              color: textColor,
              textAlign: 'left',
            }}

          >
            {name}
          </Typography>
        </Stack>

      </Stack>

    </Paper>
  );
}
