import React from 'react';
import {
  Box, Container, Typography,
} from '@mui/material';

export default function NotFound() {
  return (
    <Box sx={{
      height: 'auto',
      '@media (min-width: 600px)': {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
    }}
    >
      <Container sx={{
        pt: 10,
        paddingBottom: 10,
        pl: { sm: 2.5, md: 3, lg: 7 },
        pr: { sm: 2.5, md: 3, lg: 12 },
      }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '80vh',
          }}
        >
          <Typography variant="h1">
            404
          </Typography>
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            Sorry the page you&apos;re looking for doesn&apos;t exist.
          </Typography>
        </Box>
      </Container>
    </Box>

  );
}
