import './App.css';
import React from 'react';
// import { SettingsProvider } from './contexts/SettingsContext';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import { ThemeProvider } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  createTheme,
} from '@mui/material';
import ThemeProvider from './theme';
import HeaderNavBar from './components/NavBar/HeaderNavBar';
import Home from './components/Home';
import WhatIsQuantaKey from './components/WhatIsQuantaKey/WhatIsQuantaKey';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import About from './components/About/About';
import Documentation from './components/Documentation/Documentation';
import BlogLanding from './components/Blog/BlogLanding';
import MyFaceAndVoiceIdentifyMe from './components/Blog/blogs/MyVoiceAndFaceIdentifyMe';
import ResponseCodesDemystified from './components/Blog/blogs/ResponseCodesDemystified';
import TwoFA from './components/Blog/blogs/TwoFA';
import VoicePrintPhrases from './components/Blog/blogs/VoicePrintPhrases';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import Branding from './components/Branding/Branding';
import NotFound from './components/NotFound/NotFound';
import DeepFakesVsSecurity from './components/Blog/blogs/DeepFakesVsSecurity';
// import ThemeColorPresets from './components/ThemeColorPresets';
// import RtlLayout from './components/RtlLayout';
// import MotionLazyContainer from './components/animate/MotionLazyContainer';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#7F5CAD',
    },
    culturedWhite: {
      main: '#FFFFFF',
    },
    carolinaBlue: {
      main: '#009DDC',
    },
    safetyOrange: {
      main: '#F17300',
    },
  },
});

function App() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <Helmet prioritizeSeoTags>
          <title>VoiceIt - biometric access protection</title>
          <meta name="description" content="biometric access protection" />
        </Helmet>
        <HeaderNavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="home/:source" element={<Home />} />
          <Route path="whatIsQuantaKey" element={<WhatIsQuantaKey />} />
          <Route path="contactUs" element={<Contact />} />
          <Route path="privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="about" element={<About />} />
          <Route path="documentation" element={<Documentation />} />
          <Route path="blog" element={<BlogLanding />} />
          <Route path="blog/myFaceAndVoiceIdentifyMe" element={<MyFaceAndVoiceIdentifyMe />} />
          <Route path="blog/responseCodesDemystified" element={<ResponseCodesDemystified />} />
          <Route path="blog/twoFa" element={<TwoFA />} />
          <Route path="blog/voicePrintPhrases" element={<VoicePrintPhrases />} />
          <Route path="blog/deepFakesVsSecurity" element={<DeepFakesVsSecurity />} />
          <Route path="termsAndConditions" element={<TermsAndConditions />} />
          <Route path="branding" element={<Branding />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
