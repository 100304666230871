import {
  Stack, Grid, Container, Typography, TextField, Paper,
  MenuItem, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, ToggleButton, FormControlLabel,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import { m } from 'framer-motion';
import ContactMapInfo from './ContactMapInfo';
import { styled } from '@mui/material/styles';
import { PopupModal } from "react-calendly";
import { responsiveFontSizes } from '../../utils/getFontValue';


const HigherTextInput = styled(TextField)(() => ({
  '& .MuiFilledInput-input': {
    minHeight: '1.6em'
  },
}));

export const pageHeader = 'Co-Pilot Program';
export const headerSubtext = 'Please fill out the form below to schedule an introductory meeting.';

export default function Contact() {
  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      quantaKey: true,
      quantaSafe: false,
      fullName: '',
      phone: '',
      email: '',
      message: '',
      purpose: '',
    },
  });
  const [openCalendly, setOpenCalendly] = useState(false);
  const [quantaSafeCheckColor, setQuantaSafeCheckColor] = useState("#919EAB");
  const [quantaKeyCheckColor, setQuantaKeyCheckColor] = useState("#919EAB");


  const [calendlyPrefill, setCalendlyPrefill] = useState({
    name: '',
    email: '',
    customAnswers: {
      a2: '', //phone
      a3: '', //industry
      a5: ''  //extra field 
    }
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
    reset();
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const { fullName, email, phone, message, purpose, quantaKey, quantaSafe } = data;
    // TODO: put these in a map or some kind of variables instead of 
    // using strings
    if (purpose === 'Commercial' || purpose === 'Government' || purpose === 'Twilio Integration') {
      setIsLoading(false);
      let productSelection = '';
      if (quantaKey === true && quantaSafe === true) {
        productSelection = '3'
      } else if (quantaKey === true) {
        productSelection = '2'
      } else if (quantaSafe === true) {
        productSelection = '1'
      }
      setCalendlyPrefill({
        name: fullName,
        email: email,
        customAnswers: {
          a2: phone, //phone
          a3: purpose,
          a4: message,
        }
      })
      setOpenCalendly(true);
    } else {
      setOpenSnackBar(true);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container sx={{ padding: '78px 10px' }}>
        <Grid container spacing={3} justifyContent={{ md: 'space-between' }} rowSpacing={5}>
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              component={m.div}
              whileHover="hover"
              sx={{
                backgroundColor: '#f2f5f4',
                borderRadius: 2,
                overflow: 'hidden',
                '&:hover': {
                  boxShadow: (theme) => theme.customShadows.z24,
                },
                p: 3,
              }}
            >
              <Stack
                spacing={2}
                sx={{
                  mb: 5,
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                <Typography variant="h3" sx={{ color: '#000000' }}>{pageHeader}</Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  {headerSubtext}
                </Typography>
              </Stack>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2.5} alignItems="flex-start">
                  <Controller
                    name="fullName"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <HigherTextInput
                        {...field}
                        fullWidth
                        label="Full name"
                        error={Boolean(error)}
                        helperText={error?.message}
                      />
                    )}
                  />

                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        // eslint-disable-next-line max-len
                        // value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                        message: 'invalid email address',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <HigherTextInput
                        {...field}
                        fullWidth
                        label="Email"
                        error={Boolean(error)}
                        helperText={error?.message}
                      />
                    )}
                  />

                  <Controller
                    name="phone"
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        // value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i,
                        value: /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/i,
                        message: 'invalid number or country code (e.g. +1 in front)',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <HigherTextInput
                        {...field}
                        fullWidth
                        label="Phone"
                        error={Boolean(error)}
                        helperText={error?.message}
                      />
                    )}
                  />

                  <Controller
                    name="purpose"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="Select Purpose"
                        select
                        fullWidth
                        variant='filled'
                        error={Boolean(error)}
                        helperText={error?.message}

                      >
                        <MenuItem value={'Commercial'}><Typography sx={{ fontSize: '14px' }}>Commercial</Typography></MenuItem>
                        <MenuItem value={'Government'}><Typography sx={{ fontSize: '14px' }}>Government</Typography></MenuItem>
                        <MenuItem value={'Education'}><Typography sx={{ fontSize: '14px' }}>Education</Typography></MenuItem>
                        <MenuItem value={'Twilio Integration'}><Typography sx={{ fontSize: '14px' }}>Twilio Integration</Typography></MenuItem>
                      </TextField>
                    )}
                  />

                  <Controller
                    name="message"
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: /^.{20,}$/i,
                        // eslint-disable-next-line max-len
                        // value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                        message: 'Message must be at least 20 characters',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        multiline
                        rows={3}
                        label={<div>
                          <Typography style={{ whiteSpace: 'initial', fontSize: '0.875rem' }}>
                            Describe Use Case Details
                          </Typography>
                        </div>}
                        error={Boolean(error)}
                        helperText={error?.message}
                        sx={{ pb: 2.5 }}
                      />
                    )}
                  />


                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isLoading}
                    sx={{
                      mx: { xs: 'auto !important', md: 'unset !important' },
                      backgroundColor: '#000000',
                      '&:hover': {
                        backgroundColor: '#505050 !important',
                      },
                    }}
                  >
                    Schedule meeting
                  </LoadingButton>
                </Stack>
              </form>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
          >
            <ContactMapInfo />

          </Grid>
        </Grid>
      </Container>
      <Dialog
        open={openSnackBar}
        onClose={handleCloseSnackBar}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ color: '#000000', mb: 2 }}>
          <Typography
            variant='h3'
            sx={{
              color: '#000000', ...responsiveFontSizes({
                xs: 26, sm: 32, md: 32, lg: 32,
              }),
            }}>
            Request Received
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Thank you for your interest in VoiceIt. At this time, we are unable to fulfill educational requests for students or researchers as we strive to fulfill the needs of our commercial and government clients.
            <br />
            <br />
            If you are looking to implement VoiceIt in commercial deployment for an educational institution, please fill out the form again and select
            {" "}
            <span style={{ fontWeight: 'bold' }}>Commercial</span>
            .
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            autoFocus
            onClick={handleCloseSnackBar}>
            Ok
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <PopupModal
        url="https://calendly.com/voiceit-copilot/onboarding"
        // pageSettings={this.props.pageSettings}
        // utm={this.props.utm}
        prefill={calendlyPrefill}
        onModalClose={() => {
          setOpenCalendly(false);
          reset();
          setQuantaSafeCheckColor("#919EAB")
          setQuantaKeyCheckColor("#919EAB")
        }}
        open={openCalendly}
        rootElement={document.getElementById("root")}
      />
    </>
  );
}
