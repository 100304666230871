/* eslint-disable react/prop-types */
import React from 'react';
import {
  Paper,
} from '@mui/material';

import Image from '../Image';

export default function LogoCard({ LogoInfo, sx }) {
  const {
    // name,
    image,
  } = LogoInfo;
  return (
    <Paper
      variant="outlined"
      elevation={0}
      sx={{
        borderRadius: 2,
        overflow: 'hidden',
        ':hover': {
          boxShadow: 20,
        },
        ...sx,
      }}
    >
      <Image src={image} alt="test" sx={{ maxHeight: '200px' }} ratio="32/9" />
    </Paper>
  );
}
