/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Stack, Typography, Paper, Box,
} from '@mui/material';
// icons
import closeFilled from '@iconify/icons-carbon/close-filled';
//
import Iconify from '../Iconify';
import IconButtonAnimate from '../animate/IconButtonAnimate';

export default function Popup({ onClose }) {
  const handleClose = (event) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <Paper
      sx={{
        width: 140, overflow: 'hidden', borderRadius: 1.5, mt: 2, ml: 2, position: 'relative',
      }}
    >
      <Box sx={{
        position: 'absolute', right: 4, top: 4, zIndex: 9,
      }}
      >
        <IconButtonAnimate size="small" onClick={handleClose}>
          <Iconify
            icon={closeFilled}
            sx={{
              width: 20,
              height: 20,
              opacity: 0.48,
              color: 'common.white',
              '&:hover': { opacity: 1 },
              ...(true && {
                color: 'text.disabled',
              }),
            }}
          />
        </IconButtonAnimate>
      </Box>

      {/* {office.photo && <Image alt="photo" src={office.photo} ratio="4/3" />} */}

      <Stack
        spacing={1}
        sx={{
          p: 1.5,
          wordBreak: 'break-all',
          ...(true && {
            p: 2,
            pr: 3.5,
          }),
        }}
      >
        <Typography variant="subtitle2">Main Office</Typography>
      </Stack>
    </Paper>
  );
}
Popup.propTypes = {
  onClose: PropTypes.func,
};
