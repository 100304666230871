// ----------------------------------------------------------------------

export default function Link() {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },

      styleOverrides: {
        root: {
          cursor: 'pointer',
          color: '#75B09C'
        },
      },
    },
  };
}
