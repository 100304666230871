/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
// icons
import menuIcon from '@iconify/icons-carbon/menu';
import {
  Box,
  List,
  Stack,
  Button,
  Drawer,
  ListItemText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HexaLogo from '../../assets/images/HexaLogo.png';
import Logo from '../../assets/images/VoiceIt.png';
import HexaLogoAnimated from '../../assets/images/voiceit-logo-default.png';
// components
import Iconify from '../Iconify';

const DRAWER_WIDTH = 280;

export default function NavMobile({ navConfig, sx }) {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  // useEffect(() => {
  //   if (drawerOpen) {
  //     handleDrawerClose();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pathname]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <Iconify icon={menuIcon} onClick={handleDrawerOpen} sx={{ color: '#000000' }} fontSize="24px" />

      <Drawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: { width: DRAWER_WIDTH },
        }}
      >
        <Box sx={{ overflowX: 'auto' }}>

          <Box sx={{ px: 2.5, py: 3, lineHeight: 0 }}>
            <Button
              variant="text"
              onClick={() => {
                handleDrawerClose();
                navigate('/');
              }}
            >
              <img src={HexaLogoAnimated} style={{ width: '24px', paddingTop: '2px', marginRight: '3px' }} alt="Hexagon logo" />
              <img src={Logo} style={{ width: '100px', marginLeft: '5px' }} alt="VoiceIt logo" />
            </Button>
          </Box>

          <List sx={{ px: 2.5 }}>
            <ListItemText sx={{
              height: 48,
              textTransform: 'capitalize',
            }}
            >
              <Button
                variant="text"
                href=""
                target="_blank"
                rel="noopener"
                onClick={() => {
                  handleDrawerClose();
                  navigate('blog');
                }}
                sx={{
                  fontSize: '1rem',
                  justifyContent: 'flex-start',
                  color: '#000000',
                }}
              >
                Blog
              </Button>
            </ListItemText>

          </List>

          <Stack spacing={2} sx={{ p: 2.5, pb: 5 }}>
            <Button
              variant="contained"
              href=""
              target="_blank"
              rel="noopener"
              sx={{
                backgroundColor: '#000000',
                '&:hover': {
                  backgroundColor: '#505050 !important',
                  // color: '#637381',
                },
                fontSize: '1.1rem',
                width: '60%',
              }}
              onClick={() => {
                handleDrawerClose();
                navigate('contactUs');
              }}
            >
              Start Today
            </Button>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
}
