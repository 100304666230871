/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  Paper,
  Link,
  Stack,
} from '@mui/material';
import NET from 'vanta/dist/vanta.net.min';
import linkIcon from '@iconify/icons-carbon/launch';
import { LoadingButton } from '@mui/lab';
import Iconify from '../Iconify';
import './WhatIsQuantaKey.css';
import WhatIsQuantaKeyImage from '../../assets/images/WhatIsVoiceIt.png';
import WhatIsQuantaKeyVersSecSup from '../../assets/images/WhatIsVoiceItVersSecSup.png';
import WhatIsQuantaKeyCoverImage from '../../assets/images/CoverImages/QuantaKeyCoverImage.png';
import { responsiveFontSizes } from '../../utils/getFontValue'; // Import using relative path
import { ReactComponent as ShadowTrack } from '../../assets/images/ShadowTrack.svg';
import { ReactComponent as B4ll } from '../../assets/images/B4ll.svg';
import { ReactComponent as Acheck } from '../../assets/images/Acheck.svg';
import { ReactComponent as Axend } from '../../assets/images/Axend.svg';
import { ReactComponent as TrackTech } from '../../assets/images/TrackTech.svg';
import { ReactComponent as Biofi } from '../../assets/images/Biofi.svg';
import getWindowSize from '../../utils/getScreenSize';
import StartOurCoPilotBox from '../StartOurCoPilotBox/StartOurCoPilotBox';

const regularTextFontSizes = {
  xs: 16, sm: 18, md: 18, lg: 18,
};

function Intro({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      API 2.0 is a high-tech on-premises biometric security system created by VoiceIt
      that uses artificial intelligence to protect digital assets. It allows customers
      to add facial and voice recognition to their assets with easy-to-use software
      development kits and wrappers.
    </Typography>
  );
}

function BodyParagraph1({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      API 2.0 is powered by several neural net engines for facial and voice recognition,
      enabling unparalleled
      {' '}
      <span style={{ fontWeight: 'bold', color: '#505050' }}>access protection</span>
      {' '}
      and reducing the risk of cyber breaches.
      Furthermore, the system supports 119 content languages, including Icelandic,
      Armenian, two dialects of Swahili, and 13 dialects of English, and boasts
      fine-tuning tools that can automatically adjust neural net engines to
      better suit specific use cases.
    </Typography>
  );
}

function BodyParagraph2({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      API 2.0 has been integrated by leading telecommunications providers
      such as Vodacom, Twilio, GSMA, and VoxImplant.  They accomplished this
      by using API 2.0&apos;s easy-to-use wrappers and SDKs that support a wide
      range of programming languages, including JavaScript, Java, Python,
      C++, Go, Ruby, Rust, PHP, Scala, and Perl.
    </Typography>
  );
}

function BodyParagraph3({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      The
      {' '}
      <span style={{ fontWeight: 'bold', color: '#505050' }}>versatility</span>
      {' '}
      of API 2.0 is exemplified by its successful deployment
      in various applications. In Tanzania, SautiPass has enabled
      {' '}
      <span style={{ fontWeight: 'bold', color: '#505050' }}>secure access</span>
      {' '}
      to accounts using only voice for individuals with visual impairments,
      while Shadowtrack has adopted API 2.0&apos;s SDKs to provide a more dignified
      method of fulfilling court-ordered home incarceration requirements in the
      United States, without the need for physical attachments or
      home equipment installation.
    </Typography>
  );
}

function Conclusion({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      In addition to its security benefits, API 2.0 also offers peace of mind with
      its
      {' '}
      <span style={{ fontWeight: 'bold', color: '#505050' }}>excellent customer service</span>
      . The team is always available to answer
      questions and provide guidance on the best ways to integrate biometric
      access protection into customers&apos; systems.
    </Typography>
  );
}

const exampleSolutions = [
  {
    title: 'ShadowTrack',
    icon: <ShadowTrack />,
    text: 'ShadowVoice is an efficient identity verification system that blends voice and facial recognition with location tracking, offering flexible, anytime enrollee verification and integration with schedules, while alerting officers upon authentication failures.',
    link: 'https://www.shadowtrack.com/enrollees',
  },
  {
    title: 'B4LL',
    icon: <B4ll />,
    text: "GSMA's Biometrics for All provides biometric authentication capabilities in low-tech settings, including those with low-resolution cameras or no cameras, presenting an opportunity to overcome digital inclusion challenges in emerging markets.",
    link: 'https://biometrics.gsmainclusivetechlab.io',
  },
  {
    title: 'a. Check',
    icon: <Acheck />,
    text: 'The AttentiGroup app offers secure and efficient check-ins for corrections agency participants with biometric authentication. Its convenient self-reporting feature helps monitor movements and ensure compliance.',
    link: 'https://www.attentigroup.com/products/a-check',
  },
  {
    title: 'Axend',
    icon: <Axend />,
    text: 'Axend is an app designed to provide secure and convenient money remittance services between the United States and Mexico. It employs QuantaKey’s React Native and Web SDKs to offer biometric authentication for added security.',
    link: 'https://www.monetiva.com',
  },
  {
    title: 'TRACKphone',
    icon: <TrackTech />,
    svgDims: { width: { xs: 160, sm: 160, md: 230 }, height: { xs: 60, sm: 80, md: 105 } },
    text: 'TRACKphone™ is a fully-featured Android smartphone that includes biometric verification, location tracking, compliance monitoring,  and mobile breathalyzer aimed at  reducing recidivism rates and provide rehabilitative support.',
    link: 'https://tracktechllc.com/products/',
  },
  {
    title: 'Biofi',
    icon: <Biofi />,
    text: '$BioFi is a secure financial ecosystem that uses biometric security and digital tokens. Users can access decentralized finance products and services using $BioFi, and partners can integrate with the ecosystem through APIs.',
    link: 'https://biometricfinancial.org/about.html',
  },
];

const defaultSVGDims = { width: { xs: 60, sm: 80, md: 105 }, height: { xs: 60, sm: 80, md: 105 } };

function SolutionsCard({
  icon, text, title, svgDims, link,
}) {
  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: 2.5,
        borderStyle: 'solid',
        borderWidth: '4px',
        // backgroundColor: '#054A91',
        ':hover': {
          boxShadow: 20, // theme.shadows[20]
          backgroundColor: '#F1F3F1',
        },
        p: {
          xs: 2, sm: 2, md: 4, lg: 5,
        },
        pt: {
          xs: 2, sm: 2, md: 5, lg: 5,
        },
        pb: {
          xs: 4, sm: 4, md: 5, lg: 5,
        },
        position: 'relative',
      }}
      onClick={() => {
        window.open(link, '_blank', 'noreferrer');
      }}
    >
      <Box sx={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        mb: { xs: 0, md: 4 },
        '& > svg': svgDims || defaultSVGDims,
      }}
      >
        {icon}
      </Box>
      <Typography sx={{
        textAlign: 'center',
        fontWeight: 'bold',
        ...responsiveFontSizes({
          xs: 22, sm: 22, md: 28, lg: 28,
        }),
        color: '#000000',
      }}
      >
        {title}
      </Typography>

      <Typography sx={{
        textAlign: 'center',
        ...responsiveFontSizes({
          xs: 14, sm: 16, md: 16, lg: 16,
        }),
      }}
      >
        {text}
      </Typography>
      <Iconify icon={linkIcon} className="topRightIcon" sx={{ color: '#505050' }} />

    </Paper>
  );
}

export default function WhatIsQuantaKey() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const [vantaEffect, setVantaEffect] = useState(null);
  const myRef = useRef(null);
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(NET({
        el: myRef.current,
        color: 0xe8c22c,
        backgroundColor: 0x0,
        points: 13.00,
        maxDistance: 24.00,
        spacing: 19.00,
        showDots: false,
        zoom: 0.93,
      }));
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);
  let whatIsQuantaKeyDiagramHeight = '265px';
  if (windowSize.innerWidth >= 1100) {
    whatIsQuantaKeyDiagramHeight = '350px';
  } else if (windowSize.innerWidth >= 900) {
    whatIsQuantaKeyDiagramHeight = '300px';
  } else if (windowSize.innerWidth >= 600) {
    whatIsQuantaKeyDiagramHeight = '265px';
  }

  let whatIsQuantaKeyVersSecSupDiagramHeight = '300px';
  if (windowSize.innerWidth >= 1100) {
    whatIsQuantaKeyVersSecSupDiagramHeight = '450px';
  } else if (windowSize.innerWidth >= 900) {
    whatIsQuantaKeyVersSecSupDiagramHeight = '390px';
  } else if (windowSize.innerWidth >= 600) {
    whatIsQuantaKeyVersSecSupDiagramHeight = '300px';
  }
  return (
    <>

      <Box
        ref={myRef}
        sx={{
          backgroundImage: `url(${WhatIsQuantaKeyCoverImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: '100% 100%',
          marginTop: '64px',
        }}
      >
        <Container sx={{
          width: '100vw',
          height: {
            xs: '300px', sm: '500px', md: '700px', lg: '100vh',
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#FFFFFF',
        }}
        >
          <Grid container columnSpacing={3}>
            <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
              <Typography
                variant="h1"
                sx={{
                  color: '#EEF1EF',
                  fontFamily: 'branding',
                  fontWeight: 'bold',
                  ...responsiveFontSizes({
                    xs: 40, sm: 70, md: 80, lg: 96,
                  }),
                }}
              >
                What is API 2.0?
              </Typography>

            </Grid>

          </Grid>
        </Container>
      </Box>

      <Box sx={{
        height: 'auto',
        minHeight: { md: '100vh' },
        '@media (min-width: 600px)': {
          paddingLeft: '24px',
          paddingRight: '24px',
        },
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      >
        <Container sx={{
          py: { xs: 3, md: 3, lg: 9 },
        }}
        >
          <Grid
            container
            spacing={{
              xs: 0, sm: 0, md: 0, lg: 3,
            }}
            sx={{ justifyContent: 'center' }}
            rowSpacing={{ xs: 2, md: 0 }}
          >

            <Grid item md={6}>
              <Typography
                variant="h3"
                sx={{
                  marginBottom: '5px',
                  ...responsiveFontSizes({
                    xs: 24, sm: 32, md: 30, lg: 36,
                  }),
                  color: '#000000',
                  fontWeight: 'bold',
                }}
              >
                Biometric Access Protection.
              </Typography>
              <Intro />
              {windowSize.innerWidth < 900 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    py: 2,
                    my: { xs: 0, md: 4, lg: 2 },
                  }}
                >
                  <img src={WhatIsQuantaKeyImage} alt="QuantaSafe" style={{ height: whatIsQuantaKeyDiagramHeight }} />
                </Box>
              )}
              <BodyParagraph1 />
            </Grid>

            {windowSize.innerWidth >= 900
              && (
                <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      py: 1,
                      display: 'flex',
                    }}
                  >
                    <img src={WhatIsQuantaKeyImage} alt="QuantaSafe" style={{ height: whatIsQuantaKeyDiagramHeight }} />
                  </Box>
                </Grid>
              )}

            {windowSize.innerWidth >= 900
              && (
                <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      py: 1,
                      display: 'flex',
                    }}
                  >
                    <img src={WhatIsQuantaKeyVersSecSup} alt="QuantaSafe" style={{ height: whatIsQuantaKeyVersSecSupDiagramHeight }} />
                  </Box>
                </Grid>
              )}
            <Grid item md={6}>
              <Typography
                variant="h3"
                sx={{
                  marginBottom: '5px',
                  ...responsiveFontSizes({
                    xs: 24, sm: 32, md: 30, lg: 36,
                  }),
                  color: '#000000',
                  fontWeight: 'bold',
                }}
              >
                Versatility. Security. Support.
              </Typography>
              <BodyParagraph2 />
              {windowSize.innerWidth < 900 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    py: 2,
                    my: { xs: 0, md: 4, lg: 2 },
                  }}
                >
                  <img src={WhatIsQuantaKeyVersSecSup} alt="QuantaSafe" style={{ height: whatIsQuantaKeyVersSecSupDiagramHeight }} />
                </Box>
              )}
              <BodyParagraph3 />
              <Conclusion />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                mt: {
                  xs: 0, sm: 0, md: 1, lg: 1,
                },
                mb: 3,
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  mb: 5,
                  ...responsiveFontSizes({
                    xs: 24, sm: 32, md: 30, lg: 32,
                  }),
                  color: '#000000',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                Example API 2.0 solutions
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  rowGap: { xs: 2, sm: 4 },
                  columnGap: { xs: 2, sm: 4 },
                  pr: { xs: 0, lg: 0 },
                  // mx: { xs: 4, md: 0 },
                  gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    lg: 'repeat(3, 1fr)',
                  },
                }}
              >
                {exampleSolutions.map((solution) => (
                  <SolutionsCard
                    key={solution.title}
                    icon={solution.icon}
                    text={solution.text}
                    title={solution.title}
                    link={solution.link}
                    svgDims={solution.svgDims}
                  />
                ))}
              </Box>

            </Grid>

            <StartOurCoPilotBox />
          </Grid>

        </Container>
      </Box>
    </>
  );
}
